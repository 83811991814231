import { faEye, faEyeSlash, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { every, get } from "lodash"
import { FC, useState } from "react"
import { useForm } from "react-hook-form"
import { z, ZodSchema } from "zod"
import { PenIcon } from "../../Icons/Icon"
import { useNavigator } from "../../Navigator/useNavigator"
import { AccentButton, FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { cls } from "../cls"
import { Countdown } from "../Countdown/Countdown"
import style from "./SteppedForm.module.css"

type SteppedFormProps = {
	steps: SteppedFormBox[]
	finalStep: SteppedFormConfirmStepProps
}

export type SteppedFormBox<SchemaType = any, IdType = any> = {
	id: IdType
	header: string
	subHeader: string
	closedHeader?: string
	closedSubHeader?: string
	zodSchema: ZodSchema
	inputs: SteppedFormBoxInput[]
	state: SteppedFormBoxState
	onNextClick: (stepId: IdType, data: SchemaType) => Promise<SteppedFormBoxNextResult>
	onSuccess: (stepId: IdType, data: SchemaType) => void
	onEditClick: (stepId: IdType) => void
	defaultValues?: object
	disabled?: boolean
}

export type SteppedFormBoxNextResultSuccess = {
	type: "success"
	data?: unknown
}

export type SteppedFormBoxNextResultFail = {
	type: "failure"
	message: string
}

export type SteppedFormBoxNextResult = SteppedFormBoxNextResultSuccess | SteppedFormBoxNextResultFail

export enum SteppedFormBoxState {
	Open = "Open",
	ClosedEditable = "ClosedEditable",
	ClosedNotEditable = "ClosedNotEditable",
}

export enum SteppedFormBoxInputType {
	Text = "text",
	Password = "password",
	Email = "email",
	Number = "number",
}

export type SteppedFormBoxInput = {
	title: string
	placeHolder?: string
	type?: SteppedFormBoxInputType
	inputPath: string // react-form-hook field path
}

export const SteppedForm: FC<SteppedFormProps> = ({ steps, finalStep }: SteppedFormProps) => {
	return (
		<div className={style.steppedFormWrapper}>
			{steps.map((step) => {
				return <SteppedFormBoxStep key={step.id + "box"} step={step} />
			})}
			{every(steps, (step) => step.state === SteppedFormBoxState.ClosedEditable) ? (
				<SteppedFormConfirmStep {...finalStep} />
			) : null}
		</div>
	)
}

type SteppedFormBoxStepProps = {
	step: SteppedFormBox
}

function SteppedFormBoxStep(props: SteppedFormBoxStepProps) {
	const [nextStepLoading, setNextStepLoading] = useState(false)
	const [nextStepResult, setNextStepResult] = useState<SteppedFormBoxNextResult | null>(null)

	const [shownPasswordInput, setShownPasswordInput] = useState<Record<string, boolean>>({})

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<z.input<typeof props.step.zodSchema>>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(props.step.zodSchema)(data, context, options)
		},
		defaultValues: props.step.defaultValues,
	})

	if (
		props.step.state === SteppedFormBoxState.ClosedEditable ||
		props.step.state === SteppedFormBoxState.ClosedNotEditable
	) {
		return (
			<div className={cls(style.steppedFormBox, style.steppedFormBoxClosed)}>
				<div>
					{props.step.closedHeader ? (
						<div className={style.steppedFormBoxHeader}>{props.step.closedHeader}</div>
					) : null}
					{props.step.closedSubHeader ? (
						<div className={style.steppedFormBoxSubHeader}>{props.step.closedSubHeader}</div>
					) : null}
				</div>
				{props.step.state === SteppedFormBoxState.ClosedEditable ? (
					<PenIcon
						size={22}
						className={cls(style.steppedFormBoxClosedEditIcon, {
							[style.steppedFormBoxClosedEditIconDisabled]: props.step.disabled === true,
						})}
						onClick={() => {
							if (props.step.disabled) {
								return
							}

							props.step.onEditClick(props.step.id)
						}}
					/>
				) : null}
			</div>
		)
	}

	return (
		<div className={style.steppedFormBox}>
			<div className={style.steppedFormBoxHeader}>{props.step.header}</div>
			<div className={style.steppedFormBoxSubHeader}>{props.step.subHeader}</div>
			{props.step.inputs.map((input) => {
				return (
					<label key={props.step.id + input.inputPath}>
						<div className={style.inputTitle}>{input.title}</div>
						<input
							type={
								input.type === SteppedFormBoxInputType.Password
									? shownPasswordInput[input.inputPath]
										? "text"
										: "password"
									: input.type
							}
							disabled={props.step.disabled === true}
							className={cls(style.input, style.input)}
							{...register(input.inputPath)}
							placeholder={input.placeHolder}
						/>
						{input.type === SteppedFormBoxInputType.Password ? (
							<FontAwesomeIcon
								style={{ marginLeft: "-33px", cursor: "pointer" }}
								icon={shownPasswordInput[input.inputPath] ? faEyeSlash : faEye}
								onClick={() => {
									setShownPasswordInput((x) => {
										x[input.inputPath] = !x[input.inputPath]
										return Object.assign({}, x)
									})
								}}
							/>
						) : null}
						<SbtRHFError error={get(errors, input.inputPath)} />
					</label>
				)
			})}

			<AccentButton
				className={style.nextButton}
				disabled={nextStepLoading || !isValid}
				onClick={() => {
					if (nextStepLoading || !isValid) {
						return
					}

					setNextStepResult(null)
					setNextStepLoading(true)
					props.step
						.onNextClick(props.step.id, getValues())
						.then((res) => {
							setNextStepLoading(false)
							setNextStepResult(res)

							if (res.type === "success") {
								props.step.onSuccess(props.step.id, getValues())
							}
						})
						.catch(() => {
							setNextStepLoading(false)
							setNextStepResult({ type: "failure", message: "Något gick fel, vänligen försök igen!" })
						})
				}}>
				Nästa
				{nextStepLoading ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : null}
			</AccentButton>

			{nextStepResult?.type === "failure" ? (
				<div className={style.nextStepErrorText}>{nextStepResult.message}</div>
			) : null}
		</div>
	)
}

export type SteppedFormConfirmStepProps = {
	data: Record<string, object>
	header: string
	subHeader: string
	buttonText: string
	onClick: (data: Record<string, object>) => Promise<SteppedFormBoxNextResult>
	completedStep: SteppedFormCompletedStepProps
}

function SteppedFormConfirmStep(props: SteppedFormConfirmStepProps) {
	const [confirmStepLoading, setConfirmStepLoading] = useState(false)
	const [confirmStepResult, setConfirmStepResult] = useState<SteppedFormBoxNextResult | null>(null)

	if (confirmStepResult?.type === "success") {
		return <SteppedFormCompletedStep {...props.completedStep} data={confirmStepResult.data} />
	}

	return (
		<div className={style.steppedFormBox}>
			<div className={style.steppedFormBoxHeader}>{props.header}</div>
			<div className={style.steppedFormBoxSubHeader}>{props.subHeader}</div>

			<FinalizeButton
				disabled={confirmStepLoading}
				className={style.finalStepButton}
				onClick={() => {
					if (confirmStepLoading) {
						return
					}

					setConfirmStepLoading(true)
					setConfirmStepResult(null)
					props
						.onClick(props.data)
						.then((res) => {
							setConfirmStepLoading(false)
							setConfirmStepResult(res)
						})
						.catch(() => {
							setConfirmStepLoading(false)
							setConfirmStepResult({ type: "failure", message: "Något gick fel, vänligen försök igen!" })
						})
				}}>
				{props.buttonText}
				{confirmStepLoading ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : null}
			</FinalizeButton>

			{confirmStepResult?.type === "failure" ? (
				<div className={style.nextStepErrorText}>{confirmStepResult.message}</div>
			) : null}
		</div>
	)
}

export type SteppedFormCompletedStepProps = {
	lottieComponent?: JSX.Element
	header: string
	subHeader: string
	description?: string
	finalizeButtonText: string
	finalizeButtonRedirectPath: string
	autoRedirect?: SteppedFormCompletedStepAutoRedirect
	data?: unknown
}

export type SteppedFormCompletedStepAutoRedirect = {
	path: string
	onRedirect: (data?: unknown) => void
	description: string
	timeInSeconds: number
}

function SteppedFormCompletedStep(props: SteppedFormCompletedStepProps) {
	const navigator = useNavigator()

	return (
		<div className={cls(style.steppedFormBox, style.steppedFormBoxCompleted)}>
			{props.lottieComponent}

			<div className={style.steppedFormBoxHeader}>{props.header}</div>
			{props.subHeader ? <div className={style.steppedFormBoxSubHeader}>{props.subHeader}</div> : null}
			{props.description ? <div className={style.steppedFormFinalStepDescription}>{props.description}</div> : null}

			{props.autoRedirect ? (
				<div className={style.steppedFormFinalStepAutoRedirect}>
					{props.autoRedirect.description}
					<Countdown
						count={props.autoRedirect.timeInSeconds}
						done={() => {
							if (props.autoRedirect) {
								props.autoRedirect.onRedirect(props.data)
								navigator.open(props.autoRedirect.path)
							}
						}}
					/>
				</div>
			) : null}

			<FinalizeButton
				className={style.finalStepButton}
				onClick={() => {
					if (props.autoRedirect) {
						props.autoRedirect.onRedirect(props.data)
					}
					navigator.open(props.finalizeButtonRedirectPath)
				}}>
				{props.finalizeButtonText}
			</FinalizeButton>
		</div>
	)
}
