import { isNaN, toNumber } from "lodash"
import { DateTime } from "luxon"
import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { BeforeInstallPrompt, deferredPromptPromise } from "../App"
import { useClient } from "../Client/ClientAndUserProvider"
import { CrossIcon, LaddanerIcon } from "../Icons/Icon"
import { API } from "../network/API"
import { ModulChooserButton } from "../Orders/Components/ModulChooserButton/ModulChooserButton"
import { isModalOpen, ModulePopup } from "../Orders/Components/ModulePopup/ModulePopup"
import { SbtH1 } from "../Orders/Components/Text/SbtH1/SbtH1"
import { modalOpenQueryKey } from "../Orders/OrderContainer/OrderContainerWrapper"
import { cls } from "../Shared/cls"
import { SidebarBottomAction } from "../Sidebar/SidebarBottomAction"
import iosShare from "./ios-share.png"
import style from "./PwaInstallPrompt.module.css"

export type PwaManifest = {
	id: string
	short_name: string
	name: string
	start_url: string
	scope: string
	display: string
	theme_color: string
	background_color: string
	icons: PwaIcon[]
}

export type PwaIcon = {
	src?: string
	sizes?: string
	type?: string
	purpose?: string
}

type Props = {
	sidebarWrapperClassName?: string
	isSidebar?: boolean
}
export const PwaInstallPrompt: FC<Props> = ({ sidebarWrapperClassName, isSidebar }) => {
	const client = useClient()

	const [tick, setTick] = useState<number>(0)
	const [savedDeferredPrompt, setSavedDeferredPrompt] = useState<BeforeInstallPrompt | null>(null)
	const [logoUrl, setLogoUrl] = useState<string | null>(null)
	const [queryParams] = useSearchParams()
	const [promptPromiseHasBeenCalled, setPromptPromiseHasBeenCalled] = useState<boolean>(false)

	useEffect(() => {
		deferredPromptPromise.then((event) => {
			if (event) {
				setPromptPromiseHasBeenCalled(true)
				setSavedDeferredPrompt(event)
			}
		})
		API.getWithRetries<PwaManifest>(`/pwa-manifest-v1/${client.identifier}`).then(
			(resp) => {
				const url =
					resp.icons.find((x) => x.sizes === "192x192")?.src ||
					resp.icons.find((x) => x.sizes === "180x180")?.src ||
					resp.icons.find((x) => x.sizes === "32x32")?.src

				setLogoUrl(url ?? null)
			},
			() => {},
		)
	}, [client])

	function setDismissedToLocalstorage() {
		localStorage.setItem(`${client.identifier}.dismissed-pwa-prompt`, new Date().valueOf().toString())
	}

	function onModalClose() {
		setDismissedToLocalstorage()
		setTick(tick + 1)
	}

	function onPromptReturn(value: { outcome: "accepted" | "dismissed" }) {
		if (value.outcome === "accepted") {
			setSavedDeferredPrompt(null)
		} else {
			setDismissedToLocalstorage()
		}
		setTick(tick + 1)
	}

	function isIos() {
		const userAgent = window.navigator.userAgent.toLowerCase()
		return /iphone|ipad|ipod/.test(userAgent)
	}

	function isInStandaloneMode() {
		return "standalone" in window.navigator && window.navigator.standalone
	}

	function showAsBanner(): boolean {
		return window.innerWidth <= 710
	}

	function hideBecauseOfDismiss(): boolean {
		const item = localStorage.getItem(`${client.identifier}.dismissed-pwa-prompt`)

		if (item) {
			const number = toNumber(item)

			if (!isNaN(number)) {
				// if the time is set and it was 30 or more days since dismissal, we can show it again
				return DateTime.fromJSDate(new Date(number)).plus({ day: 30 }) >= DateTime.now()
			}
		}

		return false
	}

	// if we're in standalone we're already in "pwa context"
	// if we hide because of dismiss it means that the user has dismissed our prompt or the browsers install prompt within 30 days
	// if we're not on ios, and we lack a deferred prompt, we do nothing
	// if the modalOpenQueryKey query param exists we probably have a modal open, so we don't show the prompt
	if (
		(isSidebar &&
			(isInStandaloneMode() ||
				(!isIos() && !promptPromiseHasBeenCalled) ||
				queryParams.has(modalOpenQueryKey) ||
				isModalOpen())) ||
		(!isSidebar &&
			(isInStandaloneMode() ||
				hideBecauseOfDismiss() ||
				(!isIos() && !promptPromiseHasBeenCalled) ||
				queryParams.has(modalOpenQueryKey) ||
				isModalOpen()))
	) {
		return null
	}

	const textContentElement = isIos() ? (
		<div className={style.iosText}>
			<strong>Snabb åtkomst</strong>
			<br />
			Installera denna sida som en app (endast via safari); klicka på <img src={iosShare} alt="iOS dela knapp" /> i
			din webbläsare och sedan "Lägg till på hemskärmen"
		</div>
	) : (
		<>
			<div className={style.infoText}>
				<div>
					<strong>Snabb åtkomst?</strong>
				</div>
				<div style={{ fontSize: "14px" }}>Skaffa bokningsappen</div>
			</div>
			<div className={style.downloadButtonWrapper}>
				<ModulChooserButton
					className={style.downloadButton}
					selected={false}
					onClick={() => {
						if (isSidebar) {
							return
						}

						if (savedDeferredPrompt && savedDeferredPrompt.prompt) {
							savedDeferredPrompt.prompt().then(onPromptReturn)
						}
					}}>
					Ladda ner
					<LaddanerIcon size={14} className={style.downloadIcon} />
				</ModulChooserButton>
			</div>
		</>
	)

	if (isSidebar) {
		return (
			<>
				<SidebarBottomAction
					title={"Snabb åtkomst?"}
					narrowTitle={"Skaffa bokningsappen"}
					description={"Skaffa bokningsappen"}
					buttonText={"Ladda ner"}
					buttonIcon={<LaddanerIcon size={14} />}
					buttonAction={() => {
						if (savedDeferredPrompt && savedDeferredPrompt.prompt) {
							savedDeferredPrompt.prompt().then(onPromptReturn)
						}
					}}
				/>
			</>
		)
	}

	const bannerElement = (
		<div className={style.bannerWrapper}>
			<div className={cls(style.innerBannerWrapper, { [style.iosInnerBannerWrapper]: isIos() })}>
				<div className={!isIos() ? style.logoWrapper : undefined}>
					<img className={style.customerLogo} src={logoUrl ?? undefined} alt="Kundlogga" />
				</div>
				{textContentElement}
				<CrossIcon
					size={22}
					className={style.closeXIcon}
					iconClassName={style.closeXIconInside}
					onClick={() => {
						setDismissedToLocalstorage()
						setTick(tick + 1)
					}}
				/>
			</div>
		</div>
	)

	if (showAsBanner()) {
		return <div className={style.mobileBanner}>{bannerElement}</div>
	} else {
		return (
			<ModulePopup onClose={() => onModalClose()} className={style.modal}>
				<div>
					<SbtH1 styles={{ marginBottom: "24px" }}>Ladda ner som en app?</SbtH1>
					{bannerElement}
				</div>
			</ModulePopup>
		)
	}
}
