import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { AbsolutCentered } from "AbsolutCentered/AbsolutCentered"
import {
	ConsumerState,
	CustomerPortalConsumerInformation,
	GetConsumer,
} from "CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { Users } from "CustomerPortal/Users/Users"
import { exhaustive } from "exhaustive"
import { ArrowLeftIcon, CrossIcon } from "Icons/Icon"
import { Loader } from "Loader/Loader"
import { isNull, isString } from "lodash"
import { API, ServerError } from "network/API"
import { FC, useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerProjectMode } from "../../Client/ConsumerCatalogContext"
import { useConsumerConfiguration } from "../../Client/useConsumerConfiguration"
import { ConsumerContactPersons } from "../../CustomerPortal/ConsumerContactPersons/ConsumerContactPersons"
import { CustomerPortalConsumerInvoicingInformation } from "../../CustomerPortal/CustomerPortalConsumerInvoicingInformation/CustomerPortalConsumerInvoicingInformation"
import {
	CustomerPortalOrders,
	GetOrder,
	GetOrdersResponse,
	Paging,
} from "../../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { CustomerPortalOrdersDocuments } from "../../CustomerPortal/CustomerPortalOrdersDocuments/CustomerPortalOrdersDocuments"
import { CustomerPortalProjectsManager } from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { getLogger } from "../../Logging/getLogger"
import { WeFix } from "../../Lottie/AnimationComponents"
import { useNavigator } from "../../Navigator/useNavigator"
import { AcceptButton, DenyButton, FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { HorizontalScrollBox } from "../../Orders/Components/HorizontalScrollBox/HorizontalScrollBox"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { convertTextToInitials, parseNumberSafe } from "../../Orders/Helpers"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import { ClientPortalConsumerList } from "../ClientPortalConsumerList/ClientPortalConsumerList"
import {
	ConsumerListFilterDataSchema,
	ConsumerListFilterDataSchemaType,
} from "../ConsumerListFilterSidebar/ConsumerListFilterSidebar"
import style from "./ClientPortalConsumerManager.module.css"

type Props = {}

export type GetConsumers = {
	consumers: GetConsumer[]
	paging: Paging
}

function getConsumers(
	clientIdentifier: string,
	filters: ConsumerListFilterDataSchemaType,
	pageNumber: number,
	pageSize: number,
) {
	return API.postWithRetries<GetConsumers, ConsumerListFilterDataSchemaType>(
		`/customer-portal/consumer-manager-v1/${clientIdentifier}/list?page=${pageNumber}&size=${pageSize}`,
		filters,
	)
}

const logger = getLogger("ClientPortalConsumerManager")

enum SelectedTab {
	Overview = "overview",
	Users = "users",
	Projects = "projects",
	History = "history",
	Documents = "documents",
}

export function consumerStateToHumanText(state: ConsumerState) {
	return exhaustive(state, {
		[ConsumerState.Active]: () => "Aktiv",
		[ConsumerState.Inactive]: () => "Inaktiv",
		[ConsumerState.Removed]: () => "Borttagen",
		[ConsumerState.Pending]: () => "Väntande",
		[ConsumerState.Denied]: () => "Nekad",
		_: () => "Okänd",
	})
}

const AcceptanceFormSchema = z.object({
	comment: z.string().max(150, "För lång kommentar"),
})

type AcceptanceFormSchemaType = z.input<typeof AcceptanceFormSchema>

type AcceptOrDenyPendingConsumerBody = {
	consumerId: string
	comment: string | null
}

export const ClientPortalConsumerManager: FC<Props> = () => {
	const auth = useAuth()
	const client = useClient()
	const navigator = useNavigator()
	const permissions = usePermissions()

	const [consumers, setConsumers] = useState<GetConsumer[]>([])
	const [paging, setPaging] = useState<Paging | null>(null)

	const [newConsumer, setNewConsumer] = useState<GetConsumer | null>(null)

	const [acceptanceOutcomeClicked, setAcceptanceOutcomeClicked] = useState<"accept" | "deny" | "re-accept" | null>(null)
	const [acceptanceSubmitFailed, setAcceptanceSubmitFailed] = useState(false)
	const [acceptanceSubmitting, setAcceptanceSubmitting] = useState(false)
	const [acceptanceSuccessful, setAcceptanceSuccessful] = useState(false)

	const [dataLoading, setDataLoading] = useState(true)

	const [queryParams, setQueryParams] = useSearchParams()

	const [ordersWithDocumentsLoading, setOrdersWithDocumentsLoading] = useState(false)
	const [ordersWithDocuments, setOrdersWithDocuments] = useState<GetOrder[] | null>(null)
	const [currentSelectedConsumer, setCurrentSelectedConsumer] = useState<GetConsumer | null>(null)

	const currentSelectedTab =
		queryParams.has("tab") && Object.values(SelectedTab).includes(queryParams.get("tab") as any)
			? (queryParams.get("tab") as SelectedTab)
			: SelectedTab.Overview

	const consumerCatalogConfiguration = useConsumerConfiguration(client.identifier, currentSelectedConsumer?.id, true)
	const consumerConfigValid =
		!isNull(consumerCatalogConfiguration) && !isString(consumerCatalogConfiguration) && !!currentSelectedConsumer

	const {
		register: acceptanceFormRegister,
		formState: acceptanceFormState,
		getValues: acceptanceFormGetValues,
		reset: acceptanceFormReset,
	} = useForm<AcceptanceFormSchemaType>({
		mode: "onChange",
		resolver: async (data, context, options) => {
			return zodResolver(AcceptanceFormSchema)(data, context, options)
		},
		defaultValues: {
			comment: "",
		},
	})

	const fetchConsumers = useCallback(() => {
		if (queryParams.get("consumerId") || !permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Read)) {
			return
		}
		setCurrentSelectedConsumer(null)

		setDataLoading(true)
		const page = getPageNumber() - 1
		const size = getPageSize()
		getConsumers(client.identifier, getActiveFilters(), page, size).then(
			(resp: GetConsumers) => {
				setConsumers(resp.consumers)
				setPaging(resp.paging)
				setDataLoading(false)
			},
			(err: ServerError<any>) => {
				if (err.status === 401 || err.status === 403) {
					navigator.open("order")
				}
			},
		)
	}, [queryParams, permissions])

	useEffect(() => {
		const consumerId = queryParams.get("consumerId")
		if (consumerId && currentSelectedConsumer?.id !== consumerId) {
			fetchAndSetCurrentConsumer(consumerId)
		} else if (!consumerId) {
			setCurrentSelectedConsumer(null)
			setAcceptanceOutcomeClicked(null)
			acceptanceFormReset()
		}
	}, [queryParams])

	useEffect(() => {
		fetchConsumers()
	}, [fetchConsumers])

	useEffect(() => {
		if (!auth.IsLoggedInClient || !permissions.isAllowed(PermissionAreaLocation.Client_Consumers_Read)) {
			navigator.open("order")
		}
	}, [auth.IsLoggedInClient, navigator, permissions])

	useEffect(() => {
		if (!auth.IsLoggedInClient) {
			return
		}
		if (newConsumer !== null) {
			setQueryParams((queryParams) => {
				queryParams.set("consumerId", newConsumer.id)
				return queryParams
			})
			setNewConsumer(null)
		}
	}, [auth.IsLoggedInClient, newConsumer])

	function fetchAndSetCurrentConsumer(id: string) {
		return API.getWithRetries<GetConsumer>(
			`/customer-portal/consumers-v1/${client.identifier}/details/${id}`,
			true,
			undefined,
			10,
		)
			.then((res) => {
				setCurrentSelectedConsumer(res)
			})
			.catch((err) => {
				logger.error(`Failed to fetch consumer in consumer manager with error ${err}`)
				unSelectConsumer()
			})
	}

	function setConsumerAsNotNew(consumerId: string) {
		API.post(`/customer-portal/consumers-v1/${client.identifier}/set-not-new/${consumerId}`)
			.then(() => {
				fetchAndSetCurrentConsumer(consumerId)
			})
			.catch((err) => {
				logger.error(`Failed to update consumer flag in consumer manager with error ${err}`)
				unSelectConsumer()
			})
	}

	function getActiveFilters(): ConsumerListFilterDataSchemaType {
		const ret = ConsumerListFilterDataSchema.safeParse(JSON.parse(queryParams.get("filters") ?? "{}"))

		if (ret.success) {
			return ret.data
		} else {
			return {}
		}
	}

	function getPageNumber() {
		return parseNumberSafe(queryParams.get("page"), 1, 1)
	}

	function getPageSize() {
		return parseNumberSafe(queryParams.get("pageSize"), 20, 1)
	}

	if (!auth.IsLoggedInClient) {
		return null
	}

	function unSelectConsumer() {
		queryParams.delete("consumerId")
		queryParams.delete("tab")
		queryParams.delete("page")
		queryParams.delete("pageSize")
		setQueryParams(queryParams)
		setCurrentSelectedConsumer(null)

		setAcceptanceOutcomeClicked(null)
		setAcceptanceSubmitFailed(false)
		setAcceptanceSubmitting(false)
		acceptanceFormReset()
	}

	function selectedConsumerView(): JSX.Element | null {
		if (!currentSelectedConsumer) {
			return null
		}

		return (
			<>
				<div
					className={style.seeAllConsumersButton}
					onClick={() => {
						unSelectConsumer()
					}}>
					<ArrowLeftIcon size={28} className={style.seeAllConsumersButtonText} />
					<div className={style.seeAllConsumersButtonText}>
						<strong>Se alla kunder</strong>
					</div>
				</div>
				{selectedConsumerNavigationBar()}
				<div className={style.selectedConsumerArea}>{selectedConsumerTabView()}</div>
			</>
		)
	}

	function selectTab(tab: SelectedTab) {
		if (tab !== SelectedTab.History) {
			queryParams.delete("page")
			queryParams.delete("pageSize")
			queryParams.delete("order")
			queryParams.delete("filters")
		}

		if (tab !== SelectedTab.Projects) {
			queryParams.delete("projectId")
		}

		queryParams.set("tab", tab)
		setQueryParams(queryParams)
	}

	function allUserStuffDisabled() {
		return (
			!permissions.isAllowed(PermissionAreaLocation.Consumer_Contact_Persons_Read) &&
			!permissions.isAllowed(PermissionAreaLocation.Consumer_Contact_Persons_Create) &&
			!permissions.isAllowed(PermissionAreaLocation.Consumer_Users_Read) &&
			!permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Create) &&
			!permissions.isAllowed(PermissionAreaLocation.Consumer_User_Invitations_Read)
		)
	}

	function disAllowEditingOfCurrentConsumer(): boolean {
		if (!currentSelectedConsumer) {
			return true
		}

		return (
			currentSelectedConsumer.state === ConsumerState.Pending ||
			currentSelectedConsumer.state === ConsumerState.Denied ||
			currentSelectedConsumer.state === ConsumerState.Inactive
		)
	}

	function selectedConsumerNewConsumerBox() {
		if (!currentSelectedConsumer || !currentSelectedConsumer.isNew) {
			return null
		}

		if (disAllowEditingOfCurrentConsumer()) {
			return <div className={style.informationBox}>Ny kund</div>
		}

		return (
			<div
				className={style.informationBoxDropdownBox}
				onClick={() => {
					if (!currentSelectedConsumer) {
						return
					}

					if (window.confirm("Är du säker att du vill avmarkera kunden som ny?")) {
						setConsumerAsNotNew(currentSelectedConsumer.id)
					}
				}}>
				Ny kund
				<CrossIcon size={16} />
			</div>
		)
	}

	function selectedConsumerNavigationBar(): JSX.Element | null {
		if (!currentSelectedConsumer) {
			return null
		}
		return (
			<div className={style.selectedConsumerNavWrapper}>
				<div className={style.firstRow}>
					<div className={style.profilePicture}>
						{currentSelectedConsumer.profilePictureUrl ? (
							<img
								src={currentSelectedConsumer.profilePictureUrl}
								onError={() => {
									if (currentSelectedConsumer) {
										currentSelectedConsumer.profilePictureUrl = undefined
									}
								}}
								alt=""
							/>
						) : (
							convertTextToInitials(currentSelectedConsumer.consumerDetails.name)
						)}
					</div>
					<div className={style.consumerName}>{currentSelectedConsumer.consumerDetails.name}</div>
					<div className={style.informationBoxesWrapper}>
						<div className={style.informationBox}>
							{currentSelectedConsumer.consumerDetails.type === "CompanyConsumerDetails"
								? "Företagskund"
								: "Privatpersonskund"}
						</div>
						{selectedConsumerNewConsumerBox()}
					</div>
				</div>
				<div className={style.secondRow}>
					<HorizontalScrollBox
						className={style.horizontalScrollWrapper}
						cellClassName={style.horizontalScroll}
						key={`consumerManagerMenuRow`}>
						<div
							className={cls(style.item, {
								[style.selectedItem]: currentSelectedTab === SelectedTab.Overview,
							})}
							onClick={() => {
								selectTab(SelectedTab.Overview)
							}}>
							Översikt
						</div>
						{!allUserStuffDisabled() && !disAllowEditingOfCurrentConsumer() ? (
							<div
								className={cls(style.item, {
									[style.selectedItem]: currentSelectedTab === SelectedTab.Users,
								})}
								onClick={() => {
									selectTab(SelectedTab.Users)
								}}>
								Användare
							</div>
						) : null}
						{consumerConfigValid &&
						permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Read) &&
						!disAllowEditingOfCurrentConsumer() ? (
							<div
								className={cls(style.item, {
									[style.selectedItem]: currentSelectedTab === SelectedTab.Projects,
								})}
								onClick={() => {
									selectTab(SelectedTab.Projects)
								}}>
								{consumerCatalogConfiguration.features.projectMode === ConsumerProjectMode.FullProject
									? "Projekt"
									: "Adresser"}
							</div>
						) : null}
						{permissions.isAllowed(PermissionAreaLocation.Orders_Read) &&
						!disAllowEditingOfCurrentConsumer() ? (
							<div
								className={cls(style.item, {
									[style.selectedItem]: currentSelectedTab === SelectedTab.History,
								})}
								onClick={() => {
									selectTab(SelectedTab.History)
								}}>
								Historik
							</div>
						) : null}
						{permissions.isAllowed(PermissionAreaLocation.Orders_Read) &&
						permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read) &&
						permissions.isAllowed(PermissionAreaLocation.Client_Consumer_Documents_View) &&
						!disAllowEditingOfCurrentConsumer() ? (
							<div
								className={cls(style.item, {
									[style.selectedItem]: currentSelectedTab === SelectedTab.Documents,
								})}
								onClick={() => {
									if (currentSelectedTab !== SelectedTab.Documents) {
										getOrdersWithDocuments()
										selectTab(SelectedTab.Documents)
									}
								}}>
								Dokument
							</div>
						) : null}
					</HorizontalScrollBox>
				</div>
			</div>
		)
	}

	function getOrdersWithDocuments() {
		if (
			!currentSelectedConsumer ||
			!permissions.isAllowed(PermissionAreaLocation.Orders_Read) ||
			!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read) ||
			!permissions.isAllowed(PermissionAreaLocation.Client_Consumer_Documents_View)
		) {
			return
		}
		setOrdersWithDocumentsLoading(true)

		API.getWithRetries<GetOrdersResponse>(
			`/customer-portal/orders/consumer-v1/${client.identifier}/${currentSelectedConsumer.id}/orders-with-documents`,
			true,
		)
			.then((resp) => {
				setOrdersWithDocuments(resp.orders)
			})
			.catch(() => {
				setOrdersWithDocuments([])
			})
			.finally(() => {
				setOrdersWithDocumentsLoading(false)
			})
	}

	function sendReAcceptConsumer() {
		if (
			!permissions.isAllowed(PermissionAreaLocation.Client_Pending_Consumer_Approve_Already_Denied) ||
			!currentSelectedConsumer ||
			currentSelectedConsumer.state !== ConsumerState.Denied
		) {
			return
		}

		setAcceptanceSubmitFailed(false)
		setAcceptanceSubmitting(true)

		API.post<void, void>(
			`/customer-portal/new-customer-v1/${client.identifier}/re-accept/${currentSelectedConsumer.id}`,
		).then(
			() => {
				setAcceptanceSuccessful(true)
				fetchAndSetCurrentConsumer(currentSelectedConsumer.id)
			},
			(err) => {
				logger.error(`Failed to re-accept pending consumer with error ${err}`)
				setAcceptanceSubmitting(false)
				setAcceptanceSubmitFailed(true)
			},
		)
	}

	function sendAcceptOrDenyConsumer() {
		if (!acceptanceFormState.isValid || !acceptanceOutcomeClicked || !currentSelectedConsumer) {
			return
		}

		setAcceptanceSubmitFailed(false)
		setAcceptanceSubmitting(true)

		const data: AcceptOrDenyPendingConsumerBody = {
			consumerId: currentSelectedConsumer.id,
			comment: acceptanceFormGetValues("comment") || null,
		}

		exhaustive(acceptanceOutcomeClicked, {
			accept: () => {
				API.post<void, AcceptOrDenyPendingConsumerBody>(
					`/customer-portal/new-customer-v1/${client.identifier}/accept/${currentSelectedConsumer.id}`,
					data,
				).then(
					() => {
						setAcceptanceSuccessful(true)
					},
					(err) => {
						logger.error(`Failed to accept pending consumer with error ${err}`)
						setAcceptanceSubmitting(false)
						setAcceptanceSubmitFailed(true)
					},
				)
			},
			deny: () => {
				API.post<void, AcceptOrDenyPendingConsumerBody>(
					`/customer-portal/new-customer-v1/${client.identifier}/deny/${currentSelectedConsumer.id}`,
					data,
				).then(
					() => {
						setAcceptanceSuccessful(true)
					},
					(err) => {
						logger.error(`Failed to deny pending consumer with error ${err}`)
						setAcceptanceSubmitting(false)
						setAcceptanceSubmitFailed(true)
					},
				)
			},
			"re-accept": () => {
				// do nothing, re-accept is handled elsewhere
			},
		})
	}

	function consumerReAcceptanceSection() {
		if (
			!permissions.isAllowed(PermissionAreaLocation.Client_Pending_Consumer_Approve_Already_Denied) ||
			!currentSelectedConsumer ||
			currentSelectedConsumer.state !== ConsumerState.Denied
		) {
			return null
		}

		return (
			<div className={style.box}>
				<div className={style.reAcceptTextAreaHeader}>Acceptera nekad kund?</div>
				<div className={style.reAcceptTextAreaInfoText}>
					Kunden får ingen information om att de blivit accepterade igen efter att de redan har blivit nekade.
					Ingen användarinbjudan skickas heller ut. Nya användare måste således skapas manuellt.
				</div>

				<AcceptButton
					className={style.reAcceptanceButton}
					disabled={acceptanceSubmitting}
					style={{ border: acceptanceOutcomeClicked === "re-accept" ? "1px solid black" : undefined }}
					onClick={() => {
						if (acceptanceOutcomeClicked === "re-accept") {
							setAcceptanceOutcomeClicked(null)
						} else {
							setAcceptanceOutcomeClicked("re-accept")
						}
					}}>
					Acceptera
				</AcceptButton>

				{acceptanceOutcomeClicked === "re-accept" ? (
					<FinalizeButton
						disabled={acceptanceSubmitting}
						onClick={() => {
							sendReAcceptConsumer()
						}}>
						Bekräfta val
						{acceptanceSubmitting ? <FontAwesomeIcon spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
				) : null}
				{acceptanceSubmitFailed ? (
					<div style={{ color: "var(--invalid-color)", margin: "15px 0 10px 0" }}>
						Något gick fel, vänligen försök igen
					</div>
				) : null}
			</div>
		)
	}

	function consumerAcceptanceSection() {
		if (!permissions.isAllowed(PermissionAreaLocation.Client_Pending_Consumer_Approve_Deny)) {
			return null
		}

		if (acceptanceSubmitting && acceptanceSuccessful) {
			return consumerAcceptanceSectionDone()
		}

		if (acceptanceOutcomeClicked) {
			return consumerAcceptanceSectionOutcomeSelected()
		}

		return (
			<div className={style.consumerAcceptanceSection}>
				<div className={style.commentTextAreaHeader}>Ny kundförfrågan</div>
				<div className={style.commentTextAreaInfoText}>Vill du acceptera denna som ny kund?</div>

				<div className={style.acceptanceButtons}>
					<AcceptButton
						disabled={acceptanceSubmitting}
						style={{ border: acceptanceOutcomeClicked === "accept" ? "1px solid black" : undefined }}
						onClick={() => {
							if (acceptanceOutcomeClicked === "accept") {
								setAcceptanceOutcomeClicked(null)
							} else {
								setAcceptanceOutcomeClicked("accept")
							}
						}}>
						Acceptera
					</AcceptButton>
					<DenyButton
						disabled={acceptanceSubmitting}
						style={{ border: acceptanceOutcomeClicked === "deny" ? "1px solid black" : undefined }}
						onClick={() => {
							if (acceptanceOutcomeClicked === "deny") {
								setAcceptanceOutcomeClicked(null)
							} else {
								setAcceptanceOutcomeClicked("deny")
							}
						}}>
						Neka
					</DenyButton>
				</div>
			</div>
		)
	}

	function consumerAcceptanceSectionOutcomeSelected() {
		if (
			!permissions.isAllowed(PermissionAreaLocation.Client_Pending_Consumer_Approve_Deny) ||
			!acceptanceOutcomeClicked ||
			acceptanceOutcomeClicked === "re-accept"
		) {
			return null
		}

		let headerTextColor
		let headerText

		if (acceptanceOutcomeClicked === "accept") {
			headerText = "Acceptera kunden"
			headerTextColor = "var(--valid-color)"
		} else if (acceptanceOutcomeClicked === "deny") {
			headerText = "Neka kunden"
			headerTextColor = "var(--invalid-color)"
		}

		return (
			<div className={style.consumerAcceptanceSection}>
				<div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap-reverse" }}>
					<div
						className={style.commentTextAreaHeader}
						style={{
							display: "flex",
							alignItems: "center",
							color: headerTextColor,
						}}>
						{headerText}
					</div>
					<div
						className={cls(style.backArrowWrapper, { [style.backArrowWrapperDisabled]: acceptanceSubmitting })}
						onClick={() => {
							if (acceptanceSubmitting) {
								return
							}

							setAcceptanceOutcomeClicked(null)
							setAcceptanceSubmitFailed(false)
							acceptanceFormReset()
						}}>
						<ArrowLeftIcon size={28} className={style.backArrow} />
						Gå tillbaka
					</div>
				</div>

				<textarea
					className={style.commentTextArea}
					rows={4}
					disabled={acceptanceSubmitting}
					placeholder="Lämna en kommentar till kunden"
					{...acceptanceFormRegister("comment")}
				/>
				<SbtRHFError error={acceptanceFormState.errors.comment} />

				<FinalizeButton
					disabled={!acceptanceFormState.isValid || acceptanceSubmitting}
					onClick={() => {
						sendAcceptOrDenyConsumer()
					}}>
					Skicka svar
					{acceptanceSubmitting ? (
						<FontAwesomeIcon spin={true} style={{ marginLeft: "10px" }} icon={faSpinner} />
					) : null}
				</FinalizeButton>

				{acceptanceSubmitFailed ? (
					<div style={{ color: "var(--invalid-color)", marginBottom: "10px" }}>
						Något gick fel, vänligen försök igen
					</div>
				) : null}
			</div>
		)
	}

	function consumerAcceptanceSectionDone() {
		if (
			!permissions.isAllowed(PermissionAreaLocation.Client_Pending_Consumer_Approve_Deny) ||
			acceptanceOutcomeClicked === "re-accept" ||
			!currentSelectedConsumer ||
			!acceptanceSuccessful
		) {
			return null
		}

		let lottieColor

		if (acceptanceOutcomeClicked === "accept") {
			lottieColor = "--valid-color"
		} else {
			lottieColor = "--invalid-color"
		}

		const rootStyles = getComputedStyle(document.documentElement)
		lottieColor = rootStyles.getPropertyValue(lottieColor)

		return (
			<div className={style.consumerAcceptanceSection} style={{ alignItems: "center" }}>
				<CrossIcon
					size={22}
					className={style.crossIcon}
					onClick={(e) => {
						fetchAndSetCurrentConsumer(currentSelectedConsumer.id).then(
							() => {
								setAcceptanceSuccessful(false)
								setAcceptanceOutcomeClicked(null)
								setAcceptanceSubmitting(false)
							},
							() => {},
						)
					}}
				/>

				<WeFix style={{ maxWidth: "350px", marginTop: "-50px" }} color={lottieColor} />

				<div className={style.commentTextAreaHeader}>Svaret är skickat till kunden</div>
			</div>
		)
	}

	function selectedConsumerTabView(): JSX.Element | null {
		if (!currentSelectedConsumer || !currentSelectedTab) {
			return null
		}

		let ret: JSX.Element

		let allowTabSelection = true

		if (currentSelectedTab === SelectedTab.Users && allUserStuffDisabled()) {
			allowTabSelection = false
		} else if (
			currentSelectedTab === SelectedTab.Projects &&
			!permissions.isAllowed(PermissionAreaLocation.Consumer_Addresses_Read)
		) {
			allowTabSelection = false
		} else if (currentSelectedTab === SelectedTab.Documents) {
			// Only allow documents tab if you can read orders, attachments from said orders, and view the tab
			if (
				!permissions.isAllowed(PermissionAreaLocation.Orders_Read) ||
				!permissions.isAllowed(PermissionAreaLocation.Order_Attachments_Read) ||
				!permissions.isAllowed(PermissionAreaLocation.Client_Consumer_Documents_View)
			) {
				allowTabSelection = false
			}
		}

		if (!allowTabSelection) {
			setTimeout(() => {
				selectTab(SelectedTab.Overview)
			}, 200)
			return null
		}

		switch (currentSelectedTab) {
			case SelectedTab.Overview:
				ret = (
					<div
						className={cls(style.selectedConsumerSection, {
							[style.consumerInfoAndInvoicingInformation]: permissions.isAllowed(
								PermissionAreaLocation.Consumer_Invoicing_Information_Read,
							),
						})}>
						<CustomerPortalConsumerInformation
							className={style.consumerInfo}
							consumer={currentSelectedConsumer}
							hideAttachments={
								currentSelectedConsumer.state === ConsumerState.Pending ||
								currentSelectedConsumer.state === ConsumerState.Denied
							}
							onInactivate={() => {}}
							onConsumerUpdated={() => {
								if (currentSelectedConsumer) {
									fetchAndSetCurrentConsumer(currentSelectedConsumer.id)
								}
							}}
						/>
						{currentSelectedConsumer.state === ConsumerState.Denied ? (
							consumerReAcceptanceSection()
						) : currentSelectedConsumer.state === ConsumerState.Pending ? (
							consumerAcceptanceSection()
						) : (
							<CustomerPortalConsumerInvoicingInformation consumerId={currentSelectedConsumer.id} />
						)}
					</div>
				)
				break
			case SelectedTab.Users:
				ret = (
					<div className={cls(style.selectedConsumerSection, style.usersAndContactPersons)}>
						<Users
							type={"Consumer"}
							consumerId={currentSelectedConsumer.id}
							clientIdentifier={client.identifier}
						/>
						<ConsumerContactPersons consumerId={currentSelectedConsumer.id} />
					</div>
				)
				break
			case SelectedTab.Projects:
				if (consumerConfigValid) {
					ret = (
						<div className={style.projectsWrapper}>
							<CustomerPortalProjectsManager
								consumerCatalog={consumerConfigValid ? consumerCatalogConfiguration : undefined}
								consumerId={currentSelectedConsumer.id}
							/>
						</div>
					)
				} else {
					ret = <></>
				}
				break
			case SelectedTab.History:
				ret = (
					<div className={style.ordersWrapper}>
						<CustomerPortalOrders consumerId={currentSelectedConsumer.id} />
					</div>
				)
				break
			case SelectedTab.Documents:
				if (!ordersWithDocuments && !ordersWithDocumentsLoading) {
					getOrdersWithDocuments()
				}
				ret = (
					<div style={{ position: "relative", width: "100%" }}>
						{ordersWithDocumentsLoading || !ordersWithDocuments ? (
							<AbsolutCentered>
								<Loader />
							</AbsolutCentered>
						) : (
							<CustomerPortalOrdersDocuments
								infoText={"Här visas alla dokument för alla ordrar som har minst ett dokument tillagt"}
								orders={ordersWithDocuments}
								consumerId={currentSelectedConsumer.id}
							/>
						)}
					</div>
				)
				break
			default:
				ret = <></>
		}

		return ret
	}

	function noSelectedConsumerView(): JSX.Element | null {
		if (currentSelectedConsumer) {
			return null
		}

		return (
			<>
				<ClientPortalConsumerList
					dataLoading={dataLoading}
					consumers={consumers}
					paging={paging}
					selectConsumer={(selectedConsumer) => {
						setQueryParams((queryParams) => {
							queryParams.delete("page")
							queryParams.delete("pageSize")
							queryParams.delete("filters")
							queryParams.set("consumerId", selectedConsumer.id)
							queryParams.set("tab", SelectedTab.Overview)
							return queryParams
						})
					}}
					setNewConsumer={setNewConsumer}
				/>
			</>
		)
	}

	if (consumerCatalogConfiguration === "loading") {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}
	return (
		<div className={cls(style.consumerManagerArea, { [style.noConsumerSelected]: !currentSelectedConsumer })}>
			{currentSelectedConsumer ? selectedConsumerView() : noSelectedConsumerView()}
		</div>
	)
}
