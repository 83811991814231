import { ClientPortalConsumerManager } from "ClientPortal/ClientPortalConsumerManager/ClientPortalConsumerManager"
import { HandleUserInvitation } from "CustomerPortal/HandleUserInvitation/HandleUserInvitation"
import { cloneDeep, toNumber } from "lodash"
import { Onboarding } from "Onboarding/Onboarding"
import { OnboardingCompleted } from "Onboarding/OnboardingCompleted"
import { RequestOnboarding } from "Onboarding/RequestOnboarding"
import { useEffect } from "react"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter, Outlet, Route, Routes, useLocation } from "react-router-dom"
import { z } from "zod"
import { AuthContextProvider } from "./Auth/AuthContext"
import { AuthGuard } from "./Auth/AuthGuard"
import { ClientAndUserProvider } from "./Client/ClientAndUserProvider"
import { ListClients } from "./Client/ListClients"
import { MissingPage, PreviewPageRenderer, ViewPageOnPath } from "./Client/pages/renderer/PageRenderer"
import { CustomerPortalCustomerDetails } from "./CustomerPortal/CustomerPortalCustomerDetails/CustomerPortalCustomerDetails"
import { CustomerPortalMyAccount } from "./CustomerPortal/CustomerPortalMyAccount/CustomerPortalMyAccount"
import { CustomerPortalOrders } from "./CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { CustomerPortalProjectsManager } from "./CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import { NewCustomerPage } from "./CustomerPortal/NewCustomer/NewCustomerPage"
import { NotificationsList } from "./CustomerPortal/NotificationsList/NotificationsList"
import { PasswordResetAccept } from "./CustomerPortal/PasswordResetAccept/PasswordResetAccept"
import { Crash } from "./ErrorBoundary/Crash"
import { ErrorBoundary } from "./ErrorBoundary/ErrorBoundary"
import { ClientIndex, ClientIndexLayout, ClientPageLayout } from "./Layout/ClientIndexLayout"
import { getLogger } from "./Logging/getLogger"
import { NavigatorNavigate } from "./Navigator/NavigatorNavigate"
import { OrderAcceptance } from "./OrderAcceptance/OrderAcceptance"
import { ClientRoot } from "./Orders/ClientRoot"
import { OrderCompleted } from "./Orders/OrderCompleted/OrderCompleted"
import { BasketProvider } from "./Orders/OrderContainer/BasketProvider"
import { OrderContainerWrapper } from "./Orders/OrderContainer/OrderContainerWrapper"
import { QuantityCalculatorStep, QuantityCalculatorWrapper } from "./QuantityCalculator/QuantityCalculator"
import { DemoRouter } from "./Survey/DemoRouter"

const logger = getLogger("App")

const helmetContext = {}

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
	if (issue.code === z.ZodIssueCode.too_small) {
		return { message: "Fältet är obligatoriskt" }
	}
	if (issue.code === z.ZodIssueCode.invalid_string) {
		if (issue.validation === "email") {
			return { message: "Felaktigt format på e-post" }
		}
		return { message: "Ogiltigt format på texten" }
	}
	return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMap)

function getZoomCompensationValue(): number {
	const body = document.getElementsByTagName("body")[0]

	if (!body) {
		return 1
	}

	const computedZoom = (window.getComputedStyle(body) as any).zoom
	let zoom = toNumber(computedZoom)
	if (isNaN(zoom)) {
		// If the browser doesn't support zoom we just return compensation as 1, since no compensation is needed
		return 1
	}

	/**
	 * zoom is a number <= 1
	 * we divide 10 000 by the number to get the value needed to multiply to compensate for zoom
	 * we then divide that by 10 000 to get it as a correct size for multiplying
	 *
	 * For 65% zoom this would be
	 * (10 000 / 0.65) = 15384.6153.... which is rounded to 15385
	 *
	 * It's important to do 10 000 / X to ensure that we don't do, for example, 1 / 10 000 / 10 000 which would yield a very low number
	 * Instead it would do 10 000 / 1 / 10 000 which equals 1
	 *
	 * 15385 / 10 000 = 1.5385 <- This is the number we use to compensate for zoom
	 *
	 * Without this, the calculated value doesn't actually reflect the real width, due to dpi and zoom tomfoolery...
	 */
	return Math.round(10000 / zoom) / 10000
}

window.onload = () => {
	const zoomCompensationValue = getZoomCompensationValue()

	document.documentElement.style.setProperty(
		"--100vh",
		`${document.body.getBoundingClientRect().height * zoomCompensationValue}px`,
	)
	document.documentElement.style.setProperty(
		"--100vw",
		`${document.body.getBoundingClientRect().width * zoomCompensationValue}px`,
	)
}

window.addEventListener("resize", () => {
	const zoomCompensationValue = getZoomCompensationValue()

	document.documentElement.style.setProperty(
		"--100vh",
		`${document.body.getBoundingClientRect().height * zoomCompensationValue}px`,
	)
	document.documentElement.style.setProperty(
		"--100vw",
		`${document.body.getBoundingClientRect().width * zoomCompensationValue}px`,
	)
})

type ContainApp = "ContainWithPrefix" | "OpenInParent" | "OpenInNewByChild"

type MessageData = SitesWrapperSetupMessage

type SitesWrapperSetupMessage = {
	type: "SitesWrapperSetup"
	containApp?: ContainApp
}

type SitesWrapperPortMessages = SitesWrapperPortSetupDone | SitesWrapperPortHeartBeat | SitesWrapperPortLocationUpdate
type SitesWrapperPortSetupDone = {
	type: "SetupDone"
}

type SitesWrapperPortHeartBeat = {
	type: "HeartBeat"
}

type SitesWrapperPortLocationUpdate = {
	type: "LocationUpdate"
	pathname: string
	search: string
	hash: string
}

/**
 * Holds the Sites settings for how external links should open
 * TODO, Refactor and use a context or something
 */
export let sitesWrapperContainApp: ContainApp | null = null
let sitesWrapperPort: MessagePort | null = null
window.addEventListener("message", (messageEvent) => {
	let data = null
	try {
		data = JSON.parse(messageEvent.data) as MessageData
	} catch (e) {
		//The message is not intended for this listener
		return
	}

	if (data?.type === "SitesWrapperSetup") {
		if (messageEvent.ports.length <= 0) {
			logger.error("Got SiteWrapperSetup, but port is missing!")
		} else {
			let port = messageEvent.ports[0]
			if (port == null) {
				logger.error("No port included in the SitesWrapperSetup message!")
				return
			}
			sitesWrapperPort = port
			sitesWrapperPort.onmessage = (e) => {
				logger.log("Got message on SitesWrapperPort!", cloneDeep(e))
			}
			sitesWrapperPort.onmessageerror = (e) => {
				logger.error("Error on SitesWrapperPort!", cloneDeep(e))
			}

			sitesWrapperContainApp = data?.containApp ?? null

			const setupDone: SitesWrapperPortSetupDone = {
				type: "SetupDone",
			}
			sendMessageToSitesWrapper(setupDone)

			setInterval(() => {
				sendMessageToSitesWrapper({ type: "HeartBeat" })
			}, 3000)
		}
	} else {
		logger.warn("Got unknown message: ", messageEvent)
	}
})

function sendMessageToSitesWrapper(message: SitesWrapperPortMessages) {
	if (sitesWrapperPort == null) {
		return
	}
	sitesWrapperPort.postMessage(JSON.stringify(message))
}

export function sendLocationUpdate(pathname: string, search: string, hash: string) {
	sendMessageToSitesWrapper({
		type: "LocationUpdate",
		pathname,
		search,
		hash,
	})
}

export type BeforeInstallPrompt = Event & {
	prompt?: () => Promise<{ outcome: "accepted" | "dismissed" }>
}
let deferredPromptPromiseResolve: (value: BeforeInstallPrompt) => void
export const deferredPromptPromise: Promise<BeforeInstallPrompt> = new Promise((resolve) => {
	deferredPromptPromiseResolve = resolve
})
window.addEventListener("beforeinstallprompt", (e: BeforeInstallPrompt) => {
	e.preventDefault()
	setTimeout(() => {
		deferredPromptPromiseResolve(e)
	}, 5000)
})

function App() {
	return (
		<ErrorBoundary>
			<HelmetProvider context={helmetContext}>
				<BrowserRouter>
					<AppRoutes />
				</BrowserRouter>
			</HelmetProvider>
		</ErrorBoundary>
	)
}

function AppRoutes() {
	const location = useLocation()
	const pathname = location.pathname
	const search = location.search
	const hash = location.hash

	useEffect(() => {
		logger.log("Location set:", pathname, search, hash)
		sendLocationUpdate(pathname, search, hash)
	}, [hash, pathname, search])

	return (
		<Routes>
			<Route
				element={
					<AuthContextProvider>
						<Outlet />
					</AuthContextProvider>
				}>
				<Route path="/crash" element={<Crash />} />
				<Route path="/list" element={<ListClients />} />
				<Route path="/orders" element={<OrderAcceptance />} />
				<Route path=":clientName" element={<ClientRoot />} />
				<Route path=":clientName/demo" element={<ClientAndUserProvider element={<DemoRouter />} />} />
				<Route
					path=":clientName/reset-password"
					element={<ClientAndUserProvider element={<ClientIndex element={<ClientIndexLayout />} />} />}>
					<Route path="" element={<PasswordResetAccept />} />
				</Route>
				<Route
					path=":clientName/new-customer"
					element={<ClientAndUserProvider element={<ClientIndex element={<ClientIndexLayout />} />} />}>
					<Route index element={<NewCustomerPage />} />
				</Route>
				<Route path=":clientName/my-pages/" element={<ClientAndUserProvider element={<ClientIndexLayout />} />}>
					<Route element={<AuthGuard component={<Outlet />} />}>
						<Route path="orders" element={<CustomerPortalOrders />} />
						<Route path="customer-details" element={<CustomerPortalCustomerDetails />} />
						<Route path="consumers" element={<ClientPortalConsumerManager />} />
						<Route path="my-account" element={<CustomerPortalMyAccount />} />
						<Route path="projects" element={<CustomerPortalProjectsManager />} />
						<Route path="notifications" element={<NotificationsList />} />
					</Route>
					<Route path="*" element={<NavigatorNavigate to={"order"} />} />
				</Route>
				<Route path=":clientName/order/*" element={<ClientAndUserProvider element={<ClientIndexLayout />} />}>
					<Route path="*" element={<BasketProvider element={<OrderContainerWrapper />} />} />
					<Route path="checkout/completed/:collectionNumber" element={<OrderCompleted />} />
				</Route>
				<Route
					path=":clientName/accept-invite/*"
					element={<ClientAndUserProvider element={<ClientIndexLayout />} />}>
					<Route path="*" element={<HandleUserInvitation />} />
				</Route>
				<Route path=":clientName/pages/*" element={<ClientAndUserProvider element={<ClientPageLayout />} />}>
					<Route index element={<MissingPage />} />
					<Route path=":pagePath" element={<ViewPageOnPath />} />
				</Route>
				<Route path=":clientName/preview-page/*" element={<ClientAndUserProvider element={<ClientPageLayout />} />}>
					<Route index element={<MissingPage />} />
					<Route path=":pageId" element={<PreviewPageRenderer />} />
				</Route>
				<Route
					path=":clientName/quantity-calculator/*"
					element={<ClientAndUserProvider element={<ClientPageLayout />} />}>
					<Route index element={<QuantityCalculatorWrapper step={QuantityCalculatorStep.List} />} />
					<Route path={":slug"} element={<QuantityCalculatorWrapper step={QuantityCalculatorStep.Selected} />} />
					<Route
						path={":slug/:amount"}
						element={<QuantityCalculatorWrapper step={QuantityCalculatorStep.ConfirmSummary} />}
					/>
				</Route>
				<Route path="onboarding">
					<Route index element={<RequestOnboarding />} />
					<Route path=":onboardingId" element={<Onboarding />} />
					<Route path="completed" element={<OnboardingCompleted />} />
				</Route>
			</Route>
			<Route
				path="*"
				element={
					<div style={{ display: "flex" }}>
						<div
							style={{
								marginLeft: "auto",
								marginRight: "auto",
								marginTop: "50vh",
								fontSize: "35px",
								textAlign: "center",
							}}>
							Skrappy bokningsapp
							<br />
							Framtidssäkra er verksamhet med Skrappy
							<br />
							<a href="https://skrappy.se" target="_blank">
								Läs mer om Skrappy
							</a>
						</div>
					</div>
				}
			/>
		</Routes>
	)
}

export default App
