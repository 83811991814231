import { faLocationCrosshairs, faLocationDot, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api"
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url"
import { debounce, isArray, toNumber } from "lodash"
import { Project, ProjectSchema } from "Orders/order-data-model"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import { useForm } from "react-hook-form"
import { GoogleMapComponent, maybeSetMapBoundsForExactDelivery } from "Shared/GoogleMapComponent/GoogleMapComponent"
import { z } from "zod"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerCatalogInstance, ConsumerProjectMode, useConsumerCatalog } from "../../Client/ConsumerCatalogContext"
import { GetProject } from "../../CustomerPortal/CustomerPortalProjectsManager/CustomerPortalProjectsManager"
import {
	ArrowLeftIcon,
	CrossIcon,
	KontaktIcon,
	MarkingIcon,
	PenIcon,
	PinmapBlackIcon,
	PinmapPurpleIcon,
	TelefonIcon,
	ZonkartaIcon,
} from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { getLogger } from "../../Logging/getLogger"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import { cls } from "../../Shared/cls"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { FinalizeButton } from "../Components/Form/Buttons/Buttons"
import { addModalOpenClass, removeModalOpenClass } from "../Components/ModulePopup/ModulePopup"
import {
	getCityFromAddressComponents,
	getStreetFromAddressComponents,
	getZipCodeFromAddressComponents,
} from "../Components/OrderConfirmCheckout/OrderConfirmCheckout"
import { SbtH4 } from "../Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../Components/Text/SbtInvalid/SbtRHFError"
import { getZoneIdFromPoint } from "../OrderContainer/Logic"
import { ProjectInputContactPersonSelector } from "../ProjectInputContactPersonSelector/ProjectInputContactPersonSelector"
import style from "./ProjectInputModule.module.css"

const logger = getLogger("ProjectInputModule")

export type InputProject = {
	project: Partial<Project> | GetProject
	isNew: boolean
	editingOrderItem?: boolean
}

type Props = {
	onClose: (data?: string) => void
	inputProject: InputProject
	onDone: (project: Project, isNew: boolean) => void
	exactLocationMode?: boolean
	consumerId?: string
	consumerCatalog?: ConsumerCatalogInstance
}

// Stockholm
const stockholmCenterCoordinates = {
	lat: 59.32486,
	lng: 18.06979,
}

export const libraries: Libraries = ["places", "drawing", "geometry", "marker"]

export interface PolygonTransportZone {
	id: string
	name: string
	priority: number
	color: string
	edgeColor: string
	points: google.maps.LatLngLiteral[]
	nameLocation?: google.maps.LatLngLiteral
	opacity?: number
}

export type TransportZoneApiResponse = {
	zones: PolygonTransportZone[]
}

export const googleMiniMapOptions = (
	zoomControl: boolean = false,
	gestureHandling?: string,
): google.maps.MapOptions /* Need to manually extend options type because the typings used
	   (at the time of writing) don't include the cameraControl option */ & { cameraControl?: boolean } => ({
	zoomControl: zoomControl,
	fullscreenControl: false,
	streetViewControl: false,
	gestureHandling: gestureHandling || "none",
	mapTypeControl: false,
	clickableIcons: false,
	mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
	cameraControl: false, // always default to false because we neither need nor want this
})

/**
 * Adds default html content, i.e. marker icon and related label
 * @param label
 * @param blackMarker
 * @param size
 */
export function markerDefaultHtml(label?: string, blackMarker?: boolean, size: number = 32) {
	const content = document.createElement("span")
	content.insertAdjacentHTML(
		"beforeend",
		renderToStaticMarkup(
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				{label ? <div className={"google-maps-custom-map-marker-label"}>{label}</div> : null}
				{blackMarker ? <PinmapBlackIcon size={size} /> : <PinmapPurpleIcon size={size} />}
			</div>,
		),
	)

	return content
}

export function transportZoneMarkerDefaultHtml(text: string) {
	const content = document.createElement("span")
	content.insertAdjacentHTML("beforeend", renderToStaticMarkup(<div className={"custom-map-marker"}>{text}</div>))

	return content
}

export const markerDefaultOptions = (
	label?: string,
	blackMarker?: boolean,
	size: number = 32,
): google.maps.marker.AdvancedMarkerElementOptions => {
	return {
		content: markerDefaultHtml(label, blackMarker, size),
	}
}

type CustomPolygon = google.maps.Polygon & { id: string }
type CustomPolygonOptions = google.maps.PolygonOptions & { id: string }

type PlaceToBeConfirmed = {
	place: google.maps.places.PlaceResult | null
	location: google.maps.LatLng | null
	addressComponents: google.maps.GeocoderAddressComponent[] | null
}

type EditLocation = {
	type: "mainLocation" | "exactLocation" | "newLocation"
	originalLocation: google.maps.LatLng | google.maps.LatLngLiteral | null
}
export const ProjectInputModule: FC<Props> = ({
	inputProject,
	onDone,
	onClose,
	exactLocationMode,
	consumerId: extConsumerId,
	consumerCatalog: extConsumerCatalog,
}) => {
	const auth = useAuth()
	const client = useClient()
	const consumerCatalogUse = useConsumerCatalog()
	const permissions = usePermissions()

	const simpleAddress =
		(extConsumerCatalog || consumerCatalogUse).features.projectMode === ConsumerProjectMode.SimpleAddress

	const {
		register,
		setValue,
		getValues,
		watch,
		formState: { isValid, errors },
	} = useForm<Project>({
		mode: "onChange",
		defaultValues:
			"id" in inputProject.project
				? {
						street: inputProject.project.address.street,
						city: inputProject.project.address.city,
						zipcode: inputProject.project.address.zipCode,
						coordinates: inputProject.project.address.coordinates,
						...inputProject.project,
				  }
				: inputProject.project,
		resolver: zodResolver(ProjectSchema),
	})

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
		libraries: libraries,
		language: "sv",
	})

	const [tick, setTick] = useState<number>(0)
	const [showClarifyAddress, setShowClarifyAddress] = useState<boolean>(false)
	const [placeToBeConfirmed, setPlaceToBeConfirmed] = useState<PlaceToBeConfirmed | null>(null)
	const placeToBeConfirmedRef = useRef(placeToBeConfirmed)
	placeToBeConfirmedRef.current = placeToBeConfirmed

	const [editLocationMode, setEditLocationMode] = useState<EditLocation | null>(() => {
		return exactLocationMode === true
			? {
					type: "exactLocation",
					originalLocation:
						"deliveryCoordinates" in inputProject.project
							? inputProject.project.deliveryCoordinates || null
							: null,
			  }
			: Object.keys(inputProject.project).length === 0
			? { type: "newLocation", originalLocation: null }
			: null
	})
	const editLocationModeRef = useRef(editLocationMode)
	editLocationModeRef.current = editLocationMode

	const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null)

	const mainMapRef = useRef<google.maps.Map | null>(null)
	const mainMapAddressMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
	const mainMapExactDeliveryMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
	const mainMapOverlaysRef = useRef<CustomPolygon[]>([])
	const mainMapOverlaysMarkersRef = useRef<google.maps.marker.AdvancedMarkerElement[]>([])

	const addressMiniMapRef = useRef<google.maps.Map | null>(null)
	const addressMiniMapMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)

	const exactDeliveryLocationMiniMapRef = useRef<google.maps.Map | null>(null)
	const exactDeliveryLocationMiniMapMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)

	const geocoder = useRef<google.maps.Geocoder | null>(null)
	const autocompleteBlur = useRef(false)
	const infoWindowRef = useRef<google.maps.InfoWindow | null>(null)

	const autoCompleteInputRef = useRef<HTMLInputElement | null>(null)

	const [addingNewContactPerson, setAddingNewContactPerson] = useState(false)

	const [selectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [clientSelectedConsumerId] = useBrandedLocalStorage("client-selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	useEffect(() => {
		addModalOpenClass()
		window.addEventListener("resize", () => {
			if (!mainMapRef.current) {
				return
			}

			const position: google.maps.ControlPosition = mainMapRef.current?.get("zoomControlOptions").position
			if (
				document.body.getBoundingClientRect().width <= 600 &&
				position !== google.maps.ControlPosition.RIGHT_CENTER
			) {
				mainMapRef.current.setOptions({
					zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_CENTER },
				})
			} else if (
				document.body.getBoundingClientRect().width > 600 &&
				position === google.maps.ControlPosition.RIGHT_CENTER
			) {
				mainMapRef.current.setOptions({
					zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
				})
			}
		})
	}, [])

	useEffect(() => {
		const closeWithKeypress = (e: KeyboardEvent) => {
			if (e.key === "Escape" && !addingNewContactPerson) {
				onClose()
				removeModalOpenClass()
			}
		}
		document.addEventListener("keyup", closeWithKeypress)
		return () => {
			document.removeEventListener("keyup", closeWithKeypress)
		}
	}, [onClose, addingNewContactPerson])

	useEffect(() => {
		if (isLoaded) {
			geocoder.current = new google.maps.Geocoder()

			try {
				mainMapAddressMarkerRef.current = new google.maps.marker.AdvancedMarkerElement(
					markerDefaultOptions("Vald adress"),
				)
				addressMiniMapMarkerRef.current = new google.maps.marker.AdvancedMarkerElement(markerDefaultOptions())

				mainMapExactDeliveryMarkerRef.current = new google.maps.marker.AdvancedMarkerElement(
					markerDefaultOptions("Önskad leveransplats", true),
				)
				exactDeliveryLocationMiniMapMarkerRef.current = new google.maps.marker.AdvancedMarkerElement(
					markerDefaultOptions(undefined, true),
				)
			} catch (err) {
				logger.error("Failed to initiate advanced map markers for project input map", err)
			}
		}
	}, [isLoaded])

	function unZoomMap() {
		// "as any" is needed when accessing zoom property because it's a non-standard attribute
		// Even though it's supported by ~95% of browsers
		const body = document.getElementsByTagName("body")[0]

		if (body) {
			const computedZoom = (window.getComputedStyle(body) as any).zoom
			let zoom = toNumber(computedZoom)
			if (isNaN(zoom)) {
				// If the browser doesn't support zoom we just set it to 1
				zoom = 1
			}
			const element = document.getElementById("mainMapWrapper")
			if (element) {
				const unZoom = Math.round(10000 / zoom) / 100
				;(element.style as any).zoom = unZoom + "%"
			}
		}
	}

	function onMainMapLoaded(map: google.maps.Map) {
		unZoomMap()
		mainMapRef.current = map

		setTimeout(() => {
			if (
				document.body.getBoundingClientRect().width <= 600 &&
				map.get("zoomControlOptions").position !== google.maps.ControlPosition.RIGHT_CENTER
			) {
				map.setOptions({
					zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_CENTER },
				})
			}
		}, 10)

		if (inputProject?.project && Object.keys(inputProject.project).length > 0) {
			const coordinates =
				"id" in inputProject.project ? inputProject.project.address.coordinates : inputProject.project.coordinates
			if (coordinates) {
				placeLocationMarkerOnMap(mainMapAddressMarkerRef.current, map, coordinates, true, !exactLocationMode, 150)
				setValue("coordinates", coordinates)
			}
			if ("deliveryCoordinates" in inputProject.project && inputProject.project.deliveryCoordinates) {
				placeLocationMarkerOnMap(
					mainMapExactDeliveryMarkerRef.current,
					map,
					inputProject.project.deliveryCoordinates,
					false,
				)
				setValue("deliveryCoordinates", inputProject.project.deliveryCoordinates)

				if (exactLocationMode && inputProject.project.coordinates) {
					maybeSetMapBoundsForExactDelivery(map, inputProject.project as Project, {
						top: 150,
						left: 60,
						right: 60,
						bottom: 150,
					})
				}
			}
		}

		parseTransportZonesToShapes(client.transportZonesSorted)
		addFindMyLocationButton()
		setTick(tick + 1)
	}

	const onMainMapUnmount = useCallback(() => {
		mainMapRef.current = null
	}, [])

	async function submit(data: Project) {
		onDone(data, inputProject.isNew)
		onClose("done")
		removeModalOpenClass()
	}

	async function geocode(location: string | google.maps.LatLng): Promise<google.maps.GeocoderResult | null> {
		if (!geocoder.current) {
			return null
		}

		const req: google.maps.GeocoderRequest = {}

		if (location instanceof google.maps.LatLng) {
			req.location = location
		} else {
			req.address = location
			req.componentRestrictions = { country: "SE" }
		}

		return geocoder.current.geocode(req).then(
			(response) => {
				if (response.results.length > 0) {
					return response.results[0] || null
				}
				return null
			},
			(errors) => {
				logger.error("Failed to get GeoCode.", errors)
				return null
			},
		)
	}

	function parseTransportZonesToShapes(sortedZones: PolygonTransportZone[]) {
		const mainMap = mainMapRef.current
		if (client.features.orderUiRenderTransportZones && isArray(sortedZones) && mainMap) {
			mainMapOverlaysRef.current.forEach((x) => x.setMap(null))
			mainMapOverlaysRef.current = []
			mainMapOverlaysMarkersRef.current.forEach((x) => (x.map = null))
			mainMapOverlaysMarkersRef.current = []
			if (sortedZones.length > 0) {
				const bounds = new google.maps.LatLngBounds()

				if (sortedZones[0]) {
					sortedZones[0].points.forEach((x) => {
						bounds.extend(x)
					})
				}

				if (Object.keys(inputProject.project).length === 0) {
					mainMap.setCenter(bounds.getCenter())
				}

				sortedZones.forEach((x) => {
					let shape: CustomPolygon
					const pos = x.nameLocation || x.points[0]

					if (pos) {
						setTextOnMapPosition(x.name, pos, mainMap)
					}

					shape = new google.maps.Polygon({
						paths: x.points,
						id: x.id,
						zIndex: 1000 - x.priority,
						fillColor: x.color,
						strokeColor: x.edgeColor,
						fillOpacity: x.opacity === undefined ? getFillOpacity(x.priority) : x.opacity,
					} as CustomPolygonOptions) as CustomPolygon
					shape.setMap(mainMap)
					addOnMainMapOverlayClickHandler(shape)
					mainMapOverlaysRef.current.push(shape)
				})
			}
		}
	}

	function getFillOpacity(prio: number): number {
		let ret = 0.05 * prio
		if (ret > 0.4) {
			return 0.4
		}

		return ret
	}

	function addFindMyLocationButton() {
		const ref = mainMapRef.current
		if (!ref) {
			return
		}

		const locationButton = document.createElement("button")
		locationButton.innerHTML = renderToStaticMarkup(
			<FontAwesomeIcon color="black" size={"2x"} icon={faLocationCrosshairs} />,
		)
		locationButton.classList.add("google-maps-find-my-location-button")
		locationButton.addEventListener("click", onFindMyLocationClick)
		locationButton.addEventListener("touchstart", onFindMyLocationClick)
		ref.controls[google.maps.ControlPosition.RIGHT_BOTTOM]?.push(locationButton)

		const locationButton1 = document.createElement("button")
		locationButton1.innerHTML = renderToStaticMarkup(
			<FontAwesomeIcon color="black" size={"2x"} icon={faLocationCrosshairs} />,
		)
		locationButton1.classList.add("google-maps-find-my-location-button-other")
		locationButton1.addEventListener("click", onFindMyLocationClick)
		locationButton1.addEventListener("touchstart", onFindMyLocationClick)
		ref.controls[google.maps.ControlPosition.RIGHT_CENTER]?.push(locationButton1)
	}

	function onFindMyLocationClick() {
		if (!navigator.geolocation || !mainMapRef.current || !mainMapAddressMarkerRef.current) {
			return
		}

		navigator.permissions.query({ name: "geolocation" }).then((result) => {
			const getCurrentPos = () => {
				navigator.geolocation.getCurrentPosition(
					(position: GeolocationPosition) => {
						const pos = {
							lat: position.coords.latitude,
							lng: position.coords.longitude,
						}

						mainMapRef.current?.setCenter(pos)
					},
					(err) => {
						logger.warn("Failed to get GeoLocation.", err)
					},
				)
			}
			if (result.state === "granted") {
				getCurrentPos()
			} else if (result.state === "prompt") {
				getCurrentPos()
			} else if (result.state === "denied") {
			}
		})
	}

	const addOnMainMapOverlayClickHandler = (overlay: CustomPolygon) => {
		google.maps.event.addListener(overlay, "click", (data: any) => {
			if (data?.latLng instanceof google.maps.LatLng) {
				handleMainMapClick(data)
			}
		})
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const closeInfoWindowDebounced = useCallback(
		debounce(() => {
			if (infoWindowRef.current) {
				infoWindowRef.current?.setPosition(null)
				infoWindowRef.current?.close()
			}
		}, 10000),
		[],
	)

	function getMarkerPosition(
		position: google.maps.LatLng | google.maps.LatLngLiteral | google.maps.LatLngAltitudeLiteral | null | undefined,
	): google.maps.LatLngLiteral | null {
		if (!position) {
			return null
		}

		if (position instanceof google.maps.LatLng) {
			return position.toJSON()
		}

		return position
	}

	async function handleMainMapClick(click: google.maps.MapMouseEvent) {
		if (!click.latLng) {
			return
		}

		if (editLocationModeRef.current?.type && infoWindowRef.current?.getPosition()) {
			infoWindowRef.current?.setPosition(null)
			infoWindowRef.current?.close()
		}

		if (editLocationModeRef.current?.type === "newLocation" || editLocationModeRef.current?.type === "mainLocation") {
			placeLocationMarkerOnMap(mainMapAddressMarkerRef.current, mainMapRef.current, click.latLng, false)
			setValue("coordinates", getMarkerPosition(mainMapAddressMarkerRef.current?.position) || undefined)
			await geocode(click.latLng).then((result) => {
				if (
					result?.geometry.location &&
					result?.address_components &&
					!result.partial_match &&
					getStreetFromAddressComponents(result.address_components) &&
					getCityFromAddressComponents(result.address_components)
				) {
					setValueInAddressInput(result.formatted_address)
					onPlaceChanged(null, result)
				}
			})
		} else if (editLocationModeRef.current?.type === "exactLocation") {
			let mainPosition = getMarkerPosition(mainMapAddressMarkerRef.current?.position)
			if (mainPosition != null) {
				const distance = google.maps.geometry.spherical.computeDistanceBetween(mainPosition, click.latLng)

				if (
					distance <= 1000 ||
					(distance > 1000 &&
						window.confirm(
							"Den valda platsen är mer än 1000 meter från den valda adressen, är du säker att du vill placera den där?",
						))
				) {
					placeLocationMarkerOnMap(mainMapExactDeliveryMarkerRef.current, mainMapRef.current, click.latLng, false)
					setValue(
						"deliveryCoordinates",
						getMarkerPosition(mainMapExactDeliveryMarkerRef.current?.position) || undefined,
					)
				}
			}
		} else {
			if (!infoWindowRef.current) {
				infoWindowRef.current = new google.maps.InfoWindow({
					content: "För att kunna ändra kartnålens plats måste du klicka på kartan i rutan till vänster",
					maxWidth: 200,
				})
			}
			infoWindowRef.current.open(mainMapRef.current)
			infoWindowRef.current.setPosition(click.latLng)
			closeInfoWindowDebounced()
		}
	}

	function setTextOnMapPosition(
		text: string,
		position: google.maps.LatLng | google.maps.LatLngLiteral,
		map: google.maps.Map,
	) {
		try {
			const newMarker = new google.maps.marker.AdvancedMarkerElement({
				map: map,
				position: position,
				content: transportZoneMarkerDefaultHtml(text),
			})

			mainMapOverlaysMarkersRef.current.push(newMarker)
		} catch (err) {
			logger.error(`Failed to show marker with text message on map, message: ${text}`, err)
		}
	}

	function placeLocationMarkerOnMap(
		marker: google.maps.marker.AdvancedMarkerElement | null,
		map: google.maps.Map | null,
		location: google.maps.LatLng | google.maps.LatLngLiteral,
		centerAndZoom: boolean,
		panToSide?: boolean,
		panDelay?: number,
	) {
		if (!map || !marker) {
			return
		}

		marker.map = map
		marker.position = location

		if (centerAndZoom) {
			map.setCenter(location)
			map.setZoom(19)
		}

		if (panToSide) {
			setTimeout(() => {
				map.panBy(-100, 0)
			}, panDelay || 15)
		}
	}

	async function onPlaceChanged(
		place: google.maps.places.PlaceResult | null,
		defaultGeocodeResult?: google.maps.GeocoderResult,
	) {
		let geocodeResult: google.maps.GeocoderResult | null = defaultGeocodeResult || null

		if (place) {
			if (place.geometry?.location) {
				placeLocationMarkerOnMap(mainMapAddressMarkerRef.current, mainMapRef.current, place.geometry.location, true)
			} else if (place.name) {
				await geocode(place.name).then((result) => {
					if (
						result?.geometry.location &&
						result?.address_components &&
						!result.partial_match &&
						getStreetFromAddressComponents(result.address_components) &&
						getCityFromAddressComponents(result.address_components)
					) {
						geocodeResult = result
						placeLocationMarkerOnMap(
							mainMapAddressMarkerRef.current,
							mainMapRef.current,
							result?.geometry.location,
							true,
						)
						setValueInAddressInput(geocodeResult.formatted_address)
					} else {
						if (mainMapAddressMarkerRef.current) {
							mainMapAddressMarkerRef.current.map = null
							mainMapAddressMarkerRef.current.position = null
						}

						if (addressMiniMapMarkerRef.current) {
							addressMiniMapMarkerRef.current.map = null
							addressMiniMapMarkerRef.current.position = null
						}
					}
				})
			}
		}

		setPlaceToBeConfirmed({
			place: place,
			location: null,
			addressComponents: geocodeResult?.address_components || place?.address_components || null,
		})
	}

	async function onPlaceChangedConfirm(
		place: google.maps.places.PlaceResult | null,
		addressComponents: google.maps.GeocoderAddressComponent[] | null,
		location: google.maps.LatLngLiteral | null,
	) {
		if (!autocompleteRef.current) {
			return
		}

		setValue("street", "")
		setValue("city", "")
		setValue("zipcode", "")

		if (addressMiniMapMarkerRef.current) {
			addressMiniMapMarkerRef.current.map = null
			addressMiniMapMarkerRef.current.position = null
		}

		if (exactDeliveryLocationMiniMapMarkerRef.current) {
			exactDeliveryLocationMiniMapMarkerRef.current.map = null
			exactDeliveryLocationMiniMapMarkerRef.current.position = null
		}

		if (mainMapExactDeliveryMarkerRef.current) {
			mainMapExactDeliveryMarkerRef.current.map = null
			mainMapExactDeliveryMarkerRef.current.position = null
		}

		const locationToUse: google.maps.LatLngLiteral | null = place?.geometry?.location?.toJSON() || location || null
		if (locationToUse) {
			placeLocationMarkerOnMap(mainMapAddressMarkerRef.current, mainMapRef.current, locationToUse, true, true)
			setValue("coordinates", getMarkerPosition(mainMapAddressMarkerRef.current?.position) || undefined)
		}
		if (addressComponents) {
			setValue("street", getStreetFromAddressComponents(addressComponents))
			setValue("city", getCityFromAddressComponents(addressComponents))
			setValue("zipcode", getZipCodeFromAddressComponents(addressComponents))
		} else if (place?.name) {
			setValue("street", place.name)
		}

		setShowClarifyAddress(!getValues("city"))

		setTick(tick + 1)
	}

	function clearAddressInput() {
		;(document.getElementById("autocomplete_address_input") as HTMLInputElement).value = ""
	}

	function setSearchIconsOpacity() {
		setTimeout(() => {
			const element1 = document.getElementById("autoCompleteClearWrapper")
			const element2 = document.getElementById("autoCompleteSearchButton")
			if (element1 && element2) {
				if (autoCompleteInputRef.current && autoCompleteInputRef.current?.value) {
					element1.style.opacity = "100%"
					element2.style.opacity = "100%"
				} else {
					element1.style.opacity = "70%"
					element2.style.opacity = "70%"
				}
			}
		}, 15)
	}

	function simulateEnterKeyPressOnAddressInput() {
		const element: HTMLInputElement | null = document.getElementById("autocomplete_address_input") as HTMLInputElement
		if (!element) {
			return
		}

		element.focus()
		google.maps.event.trigger(element, "keydown", {
			keyCode: 13,
		})
		setTimeout(() => {
			element.blur()
		}, 10)
	}

	function setValueInAddressInput(value: string) {
		const element: HTMLInputElement | null = document.getElementById("autocomplete_address_input") as HTMLInputElement
		if (!element) {
			return
		}

		element.value = value.endsWith(", Sverige") ? value.substring(0, value.length - 9) : value
	}

	function autocompleteSearchInputDefaultValue(): string {
		const street = getValues("street")?.trim()
		const city = getValues("city")?.trim()
		const zipcode = getValues("zipcode")?.trim()

		let ret = street ? street + ", " : ""
		ret += zipcode ? zipcode + " " : ""
		ret += city || ""

		return ret
	}

	function autoCompleteInput(): JSX.Element {
		return (
			<>
				<div
					className={cls(style.autoCompleteWrapper, {
						[style.confirmAutoComplete]: placeToBeConfirmed !== null,
					})}>
					<div
						className={cls(style.backButton, {
							[style.isEditingLocation]: editLocationMode?.type === "mainLocation",
						})}>
						<ArrowLeftIcon
							size={28}
							onClick={() => {
								if (editLocationMode?.type === "mainLocation") {
									if (editLocationMode?.originalLocation) {
										placeLocationMarkerOnMap(
											mainMapAddressMarkerRef.current,
											mainMapRef.current,
											editLocationMode?.originalLocation,
											true,
											true,
										)
									} else {
										if (mainMapAddressMarkerRef.current) {
											mainMapAddressMarkerRef.current.map = null
											mainMapAddressMarkerRef.current.position = null
										}
										if (addressMiniMapMarkerRef.current) {
											addressMiniMapMarkerRef.current.map = null
											addressMiniMapMarkerRef.current.position = null
										}
									}

									setPlaceToBeConfirmed(null)
									setEditLocationMode(null)
									setTick(tick + 1)
								} else {
									onClose()
									removeModalOpenClass()
								}
							}}
						/>
					</div>

					<div className={style.autoCompleteInputWrapper}>
						<Autocomplete
							onLoad={(x) => (autocompleteRef.current = x)}
							restrictions={{ country: "se" }}
							onPlaceChanged={() => onPlaceChanged(autocompleteRef.current?.getPlace() || null)}>
							<span className={style.autoCompleteSearchWrapper}>
								<input
									id={"autocomplete_address_input"}
									ref={autoCompleteInputRef}
									type={"text"}
									className={style.autoCompleteInput}
									onChange={() => {
										setSearchIconsOpacity()
										setPlaceToBeConfirmed(null)
									}}
									onBlur={(event) => {
										if (event.isTrusted && !autocompleteBlur.current && event.target.value) {
											event.preventDefault()
											autocompleteBlur.current = true
											simulateEnterKeyPressOnAddressInput()
											setTimeout(() => {
												autocompleteBlur.current = false
											}, 25)
										}
									}}
									autoComplete={"address-line1"}
									autoFocus={editLocationMode?.type === "newLocation"}
									defaultValue={autocompleteSearchInputDefaultValue()}
									placeholder="Någongata 17"
								/>
								<div
									id={"autoCompleteSearchButton"}
									className={cls(style.autoCompleteSearchButton, {
										[style.autoCompleteSearchButtonSearched]: placeToBeConfirmed !== null,
									})}>
									<FontAwesomeIcon
										icon={faMagnifyingGlass}
										onClick={() => {
											simulateEnterKeyPressOnAddressInput()
											setSearchIconsOpacity()
										}}
									/>
								</div>
							</span>
						</Autocomplete>
					</div>
					<div
						id={"autoCompleteClearWrapper"}
						className={style.autoCompleteClearWrapper}
						onClick={() => {
							clearAddressInput()
							setPlaceToBeConfirmed(null)
							if (mainMapAddressMarkerRef.current) {
								mainMapAddressMarkerRef.current.map = null
								mainMapAddressMarkerRef.current.position = null
							}
							setSearchIconsOpacity()
						}}>
						<CrossIcon size={26} className={style.crossIcon} iconClassName={style.crossIconInside} />
					</div>
					<>{setSearchIconsOpacity()}</>
				</div>

				{placeToBeConfirmed ? (
					<div className={style.confirmPlaceButtonWrapper}>
						<FinalizeButton
							onClick={() => {
								setPlaceToBeConfirmed(null)
								setEditLocationMode(null)
								onPlaceChangedConfirm(
									placeToBeConfirmed.place,
									placeToBeConfirmed?.addressComponents,
									getMarkerPosition(mainMapAddressMarkerRef.current?.position) ||
										getMarkerPosition(placeToBeConfirmed?.location),
								)
							}}>
							Bekräfta
						</FinalizeButton>
					</div>
				) : null}
			</>
		)
	}

	function addressElement(): JSX.Element {
		let address: JSX.Element
		if (getValues("street") || getValues("city")) {
			const zipCode = getValues("zipcode")
			address = (
				<>
					<strong>{getValues("street")?.trim()}</strong>
					{", "}
					{(zipCode ? zipCode.trim() + " " : "") + getValues("city")?.trim()}
				</>
			)
		} else {
			address = <></>
		}

		return address
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setTickDebounced = useCallback(
		debounce(() => {
			setTimeout(() => {
				setTick((tick) => tick + 1)
			}, 10)
		}, 250),
		[],
	)

	function showContactPersonSelector(): boolean {
		return !!getConsumerId() && permissions.isAllowed(PermissionAreaLocation.Consumer_Contact_Persons_Read)
	}

	function getConsumerId(): string {
		if (extConsumerId) {
			return extConsumerId
		}

		if (auth.IsLoggedInClient) {
			return clientSelectedConsumerId
		}

		if (auth.IsLoggedInConsumer) {
			return selectedConsumerId
		}

		return ""
	}

	function selectedPlaceBox(): JSX.Element | null {
		let disableConfirm
		const requireMarking =
			consumerCatalogUse.features.projectMode === ConsumerProjectMode.FullProject &&
			consumerCatalogUse.markingRequired

		if (exactLocationMode) {
			disableConfirm = false
		} else {
			if (!simpleAddress) {
				let validContact = true
				if (showContactPersonSelector()) {
					const contactPersons = getValues("contactPersons")
					validContact = isArray(contactPersons) && contactPersons.length > 0
				} else {
					validContact = !!getValues("contactName") && !!getValues("contactPhone")
				}
				disableConfirm =
					!isValid ||
					!validContact ||
					!mainMapAddressMarkerRef.current?.position ||
					(requireMarking && !watch("marking"))
			} else {
				disableConfirm = !getValues("street") || !getValues("city") || !mainMapAddressMarkerRef.current?.position
			}
		}

		return (
			<div className={style.selectedPlaceBox}>
				<section>
					<SbtH4>Adress*</SbtH4>
					<div className={style.addressAndMinimapWrapper}>
						<div style={{ width: "100%", height: "100%" }}>
							<GoogleMapComponent
								mapContainerClassName={cls(style.mapContainer)}
								center={mainMapAddressMarkerRef?.current?.position || stockholmCenterCoordinates}
								zoom={19}
								onLoad={(map) => {
									addressMiniMapRef.current = map
									if (mainMapAddressMarkerRef.current?.position) {
										placeLocationMarkerOnMap(
											addressMiniMapMarkerRef.current,
											addressMiniMapRef.current,
											mainMapAddressMarkerRef.current?.position,
											true,
										)
									} else {
										let style = document?.getElementById("coordinatesRequiredText")?.style
										if (style != null) {
											style.display = "block"
										}
									}
								}}
								onClick={() => {
									if (exactLocationMode) {
										return
									}
									setEditLocationMode({
										type: "mainLocation",
										originalLocation: mainMapAddressMarkerRef.current?.position || null,
									})
									if (infoWindowRef.current) {
										infoWindowRef.current?.setPosition(null)
										infoWindowRef.current?.close()
									}
									setSearchIconsOpacity()
									setTick(tick + 1)
								}}
								options={googleMiniMapOptions()}
								onUnmount={() => {
									if (addressMiniMapMarkerRef.current) {
										addressMiniMapMarkerRef.current.map = null
									}
									addressMiniMapRef.current = null
								}}
							/>
						</div>
						{!exactLocationMode ? (
							<div style={{ fontSize: "14px" }} className={style.selectedSectionText}>
								Klicka på kartan ovan för justera kartnålen eller söka efter en ny adress
							</div>
						) : null}
					</div>
					{!exactLocationMode ? (
						<>
							<div className={style.address}>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<span>{addressElement()}</span>{" "}
									{!exactLocationMode ? (
										<span
											onClick={() => {
												setShowClarifyAddress(!showClarifyAddress)
											}}>
											<PenIcon size={22} iconClassName={style.editAddressIcon} />
										</span>
									) : null}
								</div>
								{showClarifyAddress ? (
									<div className={style.addressNotFoundBox}>
										<section className={style.manualAddress}>
											<div className={style.contactInput}>
												<input
													{...register("street")}
													type="text"
													placeholder={"Gata*"}
													onChange={(event) => {
														setValue("street", event.target.value)
														setTickDebounced()
													}}
												/>
											</div>
											<div className={style.contactInput}>
												<input
													{...register("zipcode")}
													type="text"
													placeholder={"Postnummer"}
													onChange={(event) => {
														setValue("zipcode", event.target.value)
														setTickDebounced()
													}}
												/>
											</div>
											<div className={style.contactInput}>
												<input
													{...register("city")}
													type="text"
													placeholder={"Stad*"}
													onChange={(event) => {
														setValue("city", event.target.value)
														setTickDebounced()
													}}
												/>
											</div>
										</section>
									</div>
								) : null}
							</div>
						</>
					) : null}
					{client.features.orderUiRenderTransportZones && mainMapAddressMarkerRef.current?.position
						? transportZoneElement(mainMapAddressMarkerRef.current?.position)
						: null}
				</section>

				{exactLocationMode ? (
					selectedPlaceExactDeliveryLocationSection()
				) : !simpleAddress ? (
					<>
						<section style={{ marginBottom: showContactPersonSelector() ? "25px" : "0" }}>
							<SbtH4>Kontaktperson för leverans*</SbtH4>

							{showContactPersonSelector() ? (
								<ProjectInputContactPersonSelector
									isNew={inputProject.isNew}
									consumerId={getConsumerId()}
									project={getValues()}
									onOpenAddNew={() => {
										setAddingNewContactPerson(true)
									}}
									onCloseAddNew={() => {
										setAddingNewContactPerson(false)
									}}
									onSelected={(res) => {
										setValue(
											"contactPersons",
											res.map((x) => ({ id: x.id, name: x.name, phone: x.phone })),
										)
										setValue("contactName", undefined)
										setValue("contactPhone", undefined)
										setTimeout(() => {
											setTick((tick) => tick + 1)
										}, 100)
									}}
								/>
							) : (
								<>
									<div className={style.contactInput}>
										<div>
											<KontaktIcon size={22} />
										</div>
										<input
											{...register("contactName")}
											onChange={(val) => {
												setValue("contactPersons", undefined)
												setValue("contactName", val.target.value)
												setTimeout(() => {
													setTick((tick) => tick + 1)
												}, 100)
											}}
											type="text"
											placeholder={"Kontaktnamn"}
										/>
									</div>
									<SbtRHFError error={errors.contactName} />

									<div className={style.contactInput}>
										<div>
											<TelefonIcon size={22} />
										</div>
										<input
											{...register("contactPhone")}
											onChange={(val) => {
												setValue("contactPersons", undefined)
												setValue("contactPhone", val.target.value)
												setTimeout(() => {
													setTick((tick) => tick + 1)
												}, 100)
											}}
											type="text"
											placeholder={"Telefonnummer till kontakt"}
										/>
									</div>
									<SbtRHFError error={errors.contactPhone} />
								</>
							)}
						</section>

						<section>
							<SbtH4>Märkning{requireMarking ? "*" : null}</SbtH4>
							<div className={style.contactInput}>
								<div>
									<MarkingIcon size={22} iconClassName={style.markingIcon} />
								</div>
								<input {...register("marking")} type="text" placeholder={"Märkning/projektnamn/littera"} />
							</div>
							<SbtRHFError error={errors.marking} />
						</section>

						<div
							id={"coordinatesRequiredText"}
							style={{ display: "none" }}
							className={style.coordinatesRequiredText}>
							Du måste placera en kartnål för din adress innan du kan fortsätta
						</div>
					</>
				) : null}

				{auth.IsLoggedIn && !getConsumerId() ? (
					<div style={{ color: "#666666", fontWeight: 600, textDecoration: "underline", marginTop: "10px" }}>
						Detta projekt skapas utan en kund vald och sparas enbart tillfälligt under order-processen
					</div>
				) : null}

				<FinalizeButton
					disabled={disableConfirm}
					className={style.addressConfirmButton}
					onClick={() => {
						submit(getValues())
					}}>
					Bekräfta
				</FinalizeButton>
			</div>
		)
	}

	function transportZoneElement(point: google.maps.LatLng | google.maps.LatLngLiteral): JSX.Element | null {
		if (!client.features.orderUiRenderTransportZones) {
			return null
		}

		const zoneId = getZoneIdFromPoint(client, point instanceof google.maps.LatLng ? point.toJSON() : point)
		if (!zoneId || (zoneId && !client.transportZones[zoneId])) {
			return (
				<div className={cls(style.transportZone, style.transportZoneNotFound)}>
					<div className={style.transportZoneName}>
						Ingen transportzon kunde hittas för adressen, extra avgifter kan tillkomma i efterhand
					</div>
				</div>
			)
		}

		return (
			<div className={style.transportZone}>
				<div className={style.transportZoneName}>
					Transportzon: <strong>{client.transportZones[zoneId]?.name || ""}</strong>
				</div>
				<div style={{ display: "grid" }}>
					<ZonkartaIcon className={style.zoneMapIconWrapper} iconClassName={style.zoneMapIcon} size={40} />
				</div>
			</div>
		)
	}

	function selectedPlaceExactDeliveryLocationSection(): JSX.Element {
		let content: JSX.Element

		if (mainMapExactDeliveryMarkerRef.current?.position && exactDeliveryLocationMiniMapMarkerRef.current) {
			exactDeliveryLocationMiniMapMarkerRef.current.position = mainMapExactDeliveryMarkerRef.current?.position
		}

		if (exactDeliveryLocationMiniMapMarkerRef.current?.position) {
			content = (
				<div className={style.selectedExactLocationBox}>
					<GoogleMapComponent
						mapContainerClassName={cls(style.mapContainer)}
						center={mainMapExactDeliveryMarkerRef?.current?.position || undefined}
						zoom={19}
						onLoad={(map) => {
							exactDeliveryLocationMiniMapRef.current = map
							if (mainMapExactDeliveryMarkerRef.current?.position) {
								placeLocationMarkerOnMap(
									exactDeliveryLocationMiniMapMarkerRef.current,
									exactDeliveryLocationMiniMapRef.current,
									mainMapExactDeliveryMarkerRef.current?.position,
									true,
								)
							}
						}}
						onClick={() => {
							setEditLocationMode({
								type: "exactLocation",
								originalLocation: mainMapExactDeliveryMarkerRef.current?.position || null,
							})
						}}
						options={googleMiniMapOptions()}
						onUnmount={() => {
							if (exactDeliveryLocationMiniMapMarkerRef.current) {
								exactDeliveryLocationMiniMapMarkerRef.current.map = null
							}
							exactDeliveryLocationMiniMapRef.current = null
						}}
					/>
					<div style={{ fontSize: "14px" }} className={style.selectedSectionText}>
						Klicka på kartan ovan för att redigera kartnålens plats
					</div>
				</div>
			)
		} else {
			content = (
				<div
					className={style.selectExactLocation}
					onClick={() => {
						setEditLocationMode({ type: "exactLocation", originalLocation: null })
					}}>
					<div>
						<FontAwesomeIcon className={style.icon} icon={faLocationDot} />
					</div>
					<div>
						<strong>Placera exakt kartnål</strong>
					</div>
				</div>
			)
		}

		return (
			<section>
				<SbtH4>Exakt leveransplats?</SbtH4>
				{content}
			</section>
		)
	}

	function selectedPlaceEditExactLocationBox(): JSX.Element | null {
		if (!editLocationModeRef.current) {
			return null
		}

		return (
			<>
				<div className={style.editLocationAddressField}>
					<ArrowLeftIcon
						className={style.editLocationAddressFieldArrow}
						size={28}
						onClick={() => {
							if (mainMapExactDeliveryMarkerRef.current) {
								mainMapExactDeliveryMarkerRef.current.position =
									editLocationModeRef.current?.originalLocation
							}

							setEditLocationMode(null)
						}}
					/>
					<div className={style.editLocationAddressFieldAddress}>{addressElement()}</div>
				</div>
				<div className={style.editLocationBox}>
					<section>
						<div style={{ fontSize: "20px" }}>
							<strong>Hjälp vår chaufför att hitta rätt!</strong>
						</div>
						<div style={{ fontSize: "16px" }}>Placera kartnålen på platsen du önskar att leveransen sker</div>
					</section>

					<FinalizeButton
						style={{ marginTop: "20px" }}
						onClick={() => {
							if (exactDeliveryLocationMiniMapMarkerRef.current) {
								exactDeliveryLocationMiniMapMarkerRef.current.position =
									mainMapExactDeliveryMarkerRef.current?.position
							}
							setEditLocationMode(null)
						}}>
						Bekräfta
					</FinalizeButton>
				</div>
			</>
		)
	}

	if (!isLoaded) {
		return (
			<div className={style.wrapper}>
				<span className={cls(style.mapWrapper, style.mapWrapperLoading)}>
					<Loader />
				</span>
			</div>
		)
	}

	return (
		<div className={style.wrapper}>
			<span
				className={cls(style.closeButtonWrapper, {
					[style.isEditingLocation]: editLocationMode?.type !== undefined,
				})}
				onClick={() => {
					onClose()
					removeModalOpenClass()
				}}>
				<CrossIcon size={22} className={style.crossIcon} iconClassName={style.crossIconInside} />
			</span>

			<span className={style.mapWrapper} id="mainMapWrapper">
				<GoogleMapComponent
					mapContainerClassName={cls(style.mapContainer)}
					center={stockholmCenterCoordinates}
					zoom={10}
					onLoad={onMainMapLoaded}
					onClick={handleMainMapClick}
					options={{
						zoomControlOptions: {
							position:
								document.body.getBoundingClientRect().width <= 600
									? google.maps.ControlPosition.RIGHT_CENTER
									: google.maps.ControlPosition.RIGHT_BOTTOM,
						},
						zoomControl: true,
						fullscreenControl: false,
						streetViewControl: false,
						gestureHandling: "greedy",
						mapTypeControl: false,
						draggableCursor: "pointer",
						clickableIcons: false,
						mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
					}}
					onUnmount={onMainMapUnmount}
				/>
			</span>

			{!editLocationMode?.type
				? selectedPlaceBox()
				: editLocationMode?.type === "exactLocation"
				? selectedPlaceEditExactLocationBox()
				: autoCompleteInput()}
		</div>
	)
}
