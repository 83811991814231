import { exhaustive } from "exhaustive"
import _ from "lodash"
import { CategorySelectionSection } from "Orders/OrderContainer/SubComponents/CategorySelectionSection"
import { FC, useCallback, useEffect, useMemo } from "react"
import { useClient } from "../../../Client/ClientAndUserProvider"
import { ProductCategoryInstance } from "../../../Client/ClientInstance"
import { PreferredVATRenderPolicy } from "../../../Client/consumerCatalogConfigurationTypes"
import { preferredVATRenderPolicyAsHumanStringShortForm, useConsumerCatalog } from "../../../Client/ConsumerCatalogContext"
import { ProductSelectionMode } from "../../../Client/FeatureTypes"
import {
	ImageRenderMode,
	ProductDefinition,
	ProductServiceUnit,
	SubCategory,
} from "../../../Client/ProductDefinitionsByCategories"
import { getLogger } from "../../../Logging/getLogger"
import { useNavigator } from "../../../Navigator/useNavigator"
import { ProductImageType } from "../../../ProductDefinitions"
import { cls } from "../../../Shared/cls"
import { currencyFormatter, numberFormatter } from "../../../Shared/numberFormatter"
import { throwIllegalState } from "../../../Shared/throwIllegalState"
import { useSearchParamState } from "../../../Shared/useSearchParamState"
import { when } from "../../../Shared/when"
import { ExpandableIncrementor } from "../../Components/ExpandableIncrementor/ExpandableIncrementor"
import { HorizontalScrollBox } from "../../Components/HorizontalScrollBox/HorizontalScrollBox"
import { ProductImage } from "../../Components/ProductImage/ProductImage"
import { MbactH1 } from "../../Components/Text/MbactH1/MbactH1"
import { unitFormatter } from "../../unit-formatter"
import { useBasket } from "../BasketProvider"
import { productServiceUnitToHumanText } from "../Logic"
import style from "../OrderContainer.module.css"
import {
	ProductDefinitionWithPrice,
	ProductPriceData,
	showItemSelector,
	WasteProductDefinitionWithPrice,
} from "../resolveProductDefinitionsWithPriceData"

const logger = getLogger("ProductSelection")

const productImageTypeCustomStyle: Record<ProductImageType, string> = {
	BagLarge: "",
	BagMedium: "",
	BagSmall: "",
	BigBagExtraLarge: "",
	BigBagMega: "",
	LD10: style.customLD10,
	LD5: style.customLD5,
	LDD10: style.customLDD10,
	LDL8: style.customLDL8,
	LDL8WOH: style.customLDL8WOH,
	LDT10: style.customLDT10,
	LV11K: style.customLV11K,
	LV12: "",
	LV15K: style.customLV15K,
	LV22: "",
	LV30: "",
	LVT15: "",
	LVT15K: style.customLVT15K,
	LVT25: "",
	Vessel190L: "",
	Vessel370L: "",
	Vessel600LWOL: "",
	Vessel660L: "",
	VesselBagMedium: "",
}

type Props = {
	selectedCategoryName: string
	selectedServiceId: string | null
	availableProducts: ProductDefinitionWithPrice[] | null
	onProductCardClick: (selectedCategory: string, product: ProductDefinitionWithPrice) => void
	onProductClick: (selectedCategory: string, product: ProductDefinitionWithPrice) => void
	onProductIncrementorChange: (
		buttonClicked: "addClick" | "removeClick" | "text",
		product: ProductDefinition,
		value: number,
		currentValue: number,
		category: string,
		service: string,
	) => void
}

export const ProductSelection: FC<Props> = ({
	selectedCategoryName,
	selectedServiceId,
	availableProducts: allAvailableProducts,
	onProductCardClick,
	onProductClick,
	onProductIncrementorChange,
}) => {
	const client = useClient()
	const basket = useBasket()
	const consumerCatalog = useConsumerCatalog()
	const navigator = useNavigator()

	useEffect(() => {
		if (!selectedCategoryName) {
			return
		}

		const selectedCategory = client.findCategoryByName(selectedCategoryName)
		if (selectedCategory == null) {
			const firstCategoryName: string = client.firstCategory()?.name || ""
			navigator.open(`order?category=${firstCategoryName.replace(" ", "+")}`)
		}
	}, [client, navigator, selectedCategoryName])

	const onlySingleCategoryExists = client.categoryKeys.length <= 1

	const selectedCategory = client.findCategoryByName(selectedCategoryName)

	const { tags, selectedTag, setSelectedTag } = useTags(allAvailableProducts)

	const { subCategories, setSelectedSubCategory, selectedSubCategory } = useSubCategories(
		allAvailableProducts,
		selectedCategory,
	)

	const availableProducts = filterProductDefsOnTagAndSubCategory(allAvailableProducts, selectedTag, selectedSubCategory)

	function productCardElement(productDef: ProductDefinitionWithPrice) {
		if (!selectedCategory) {
			return null
		}

		return (
			<div
				key={selectedServiceId + productDef.id + basket.values.selectedOrderItemIndex}
				className={cls(style.sectionBox, style.productCard)}
				style={{ cursor: "pointer" }}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					onProductCardClick(selectedCategoryName, productDef)
				}}>
				<div
					className={cls(style.productInfoIcon, {
						[style.productInfoIconOutlined]:
							"url" in productDef.images.main && productDef.images.main.renderMode === ImageRenderMode.Fill,
					})}
					onClick={(event) => {
						event.preventDefault()
						event.stopPropagation()
						onProductCardClick(selectedCategoryName, productDef)
					}}>
					<strong style={{ fontSize: "20px" }}>i</strong>
				</div>
				<div className={style.productInfo}>
					<div
						style={{
							backgroundColor: "var(--product-image-background-color)",
							borderTopLeftRadius: "6px",
							borderTopRightRadius: "6px",
						}}>
						{cardElementImageElement(productDef)}
					</div>
					<hr />
					<span style={{ padding: "0 14px 0 14px" }}>
						<div className={style.productTitle}>{unitFormatter(productDef.name)}</div>
						<div className={style.productDescription}>
							{productDef.dimensions?.volume?.value} {unitFormatter(productDef.dimensions?.volume?.unit!)}
						</div>
						{productDef.dimensions?.maxWeight?.value ? (
							<div className={style.productDescription}>
								Maxlast: {numberFormatter(productDef.dimensions?.maxWeight?.value || 0)}{" "}
								{productDef.dimensions?.maxWeight?.unit}
							</div>
						) : productDef.dimensions?.weight?.value ? (
							<div className={style.productDescription}>
								Vikt: {numberFormatter(productDef.dimensions?.weight?.value || 0)}{" "}
								{productDef.dimensions?.weight?.unit}
							</div>
						) : null}
						{cardElementUnitElement(selectedCategory, selectedServiceId)}
						{productPrice(productDef)}
					</span>
				</div>
				<span style={{ padding: "0 14px 14px 14px" }}>{cardElementSelectionElement(productDef)}</span>
			</div>
		)
	}

	const cardElements = (): JSX.Element => {
		if (availableProducts == null || _.isEmpty(availableProducts)) {
			return <span className={style.infoText}>Inga produkter tillgängliga</span>
		}

		return (
			<>
				<div className={style.productCell}>
					{availableProducts.map((productDef) => {
						if (productDef.priceData.type === "Hide") {
							logger.log("Hiding productCard:", productDef.name)
							return null
						}
						return productCardElement(productDef)
					})}
				</div>
			</>
		)
	}

	function productPrice(product: ProductDefinitionWithPrice): JSX.Element | null {
		const isAmountWasteType = product.type === "WasteProductDefinition" && product.quantityType === "Amount"

		if (!isAmountWasteType) {
			return null
		}

		if (
			client.features.orderUiProductSelectionMode === ProductSelectionMode.FullPage &&
			product.category.productSelectionConfig &&
			product.category.productSelectionConfig.steps.length > 0
		) {
			return null
		}

		const priceData = product.priceData
		const unit = product.service.unit

		const priceElement = cardElementPriceElement(priceData, unit)

		if (priceElement == null) return null

		const vatTextElement = when(consumerCatalog.preferredVATRenderPolicy || PreferredVATRenderPolicy.IncludeVAT, {
			[PreferredVATRenderPolicy.ExcludeVAT]: () => {
				return (
					<>
						<br />
						{preferredVATRenderPolicyAsHumanStringShortForm(PreferredVATRenderPolicy.ExcludeVAT)}
					</>
				)
			},
			[PreferredVATRenderPolicy.IncludeVAT]: () => {
				return null
			},
			[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
				return (
					<>
						<br />
						{preferredVATRenderPolicyAsHumanStringShortForm(PreferredVATRenderPolicy.IncludeVATExplicit)}
					</>
				)
			},
		})

		return (
			<div className={style.productCardPriceCell}>
				{priceElement}
				{vatTextElement}
			</div>
		)
	}

	function cardElementPriceElement(priceData: ProductPriceData, unit: ProductServiceUnit) {
		if (priceData.article == null) {
			if (priceData.type === "EnabledButMissingPrice") {
				return <>Ej prissatt</>
			}
			return null
		}

		const article = priceData.article

		let price
		if (consumerCatalog.renderVAT) {
			price = article.resolvedLeaf.price + article.resolvedLeaf.price * article.resolvedLeaf.taxPercentage
		} else {
			price = article.resolvedLeaf.price
		}

		if (price <= 0) {
			return <>{currencyFormatter(price)}</>
		}
		return (
			<>
				{currencyFormatter(price)} / {productServiceUnitToHumanText(unit)}
			</>
		)
	}

	function cardElementImageElement(product: ProductDefinitionWithPrice): JSX.Element {
		return "url" in product.images.main ? (
			<div
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					onProductCardClick(selectedCategoryName, product)
				}}
				className={
					product.images.main.renderMode === ImageRenderMode.Fill
						? style.productImageWrapperFill
						: style.productImageWrapperFit
				}>
				<img
					className={
						product.images.main.renderMode === ImageRenderMode.Fill
							? style.productImageFill
							: style.productImageFit
					}
					src={product.images.main.url}
					alt="Produktbild"
				/>
			</div>
		) : (
			<ProductImage
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					onProductCardClick(selectedCategoryName, product)
				}}
				client={client}
				categoryName={product.categoryName}
				image={ProductImageType[product.images.main.typeImage]}
				className={productImageTypeCustomStyle[product.images.main.typeImage] ?? ""}
				wrapperClassName={style.productImageWrapperFit}
			/>
		)
	}

	function cardElementUnitElement(category: ProductCategoryInstance, serviceId: string | null) {
		if (!serviceId) {
			return null
		}

		const unit = category.type === "WasteCategory" ? category.services[serviceId]?.unit : ProductServiceUnit.Piece

		if (unit === ProductServiceUnit.Piece || consumerCatalog.pricesEnabled) {
			return null
		}

		return <div className={style.productUnitCell}>Enhet: {productServiceUnitToHumanText(unit)}</div>
	}

	function cardElementSelectionElement(product: ProductDefinitionWithPrice): JSX.Element | null {
		return exhaustive.tag(product, "type", {
			WasteProductDefinition: (product) => {
				if (
					product.category.productSelectionConfig &&
					product.category.productSelectionConfig.steps.length > 0 &&
					client.features.orderUiProductSelectionMode === ProductSelectionMode.FullPage
				) {
					// If we have the fullpage config we always show a select button regardless if service is set or not
					return (
						<div style={{ display: "flex", justifyContent: "end" }}>
							{productWasteTypeSelectionElement(product)}
						</div>
					)
				}

				if (!selectedServiceId) {
					return null
				}

				let element: JSX.Element = exhaustive.tag(product, "quantityType", {
					None: () => null,
					Waste: (product) => {
						return productWasteTypeSelectionElement(product)
					},
					Amount: () => {
						let showSelector = showItemSelector(product.priceData)
						return exhaustive(showSelector, {
							Hide: () => null,
							Show: () => productSelectionElement(product, selectedServiceId),
							ShowCallUs: () => <div>Ring oss</div>,
						})
					},
				})

				if (element == null) {
					return null
				}

				return <div className={style.productPurchaseCell}>{element}</div>
			},
			GoodsProductDefinition: (product) => {
				return <div className={style.productPurchaseCell}>{goodsProductSelectionElement(product)}</div>
			},
			_: () => {
				// Unknown type
				throwIllegalState("Unsupported product type! " + product.type)
				// return null
			},
		})
	}

	function productWasteTypeSelectionElement(product: ProductDefinitionWithPrice) {
		return (
			<button
				className={style.addProductButton}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					onProductClick(selectedCategoryName, product)
				}}>
				Välj
			</button>
		)
	}

	function goodsProductSelectionElement(product: ProductDefinitionWithPrice) {
		return (
			<button
				className={style.addProductButton}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					onProductClick(selectedCategoryName, product)
				}}>
				Välj
			</button>
		)
	}

	function incrementorDefaultValue(product: ProductDefinition): number {
		if (basket.values.selectedOrderItemIndex === null) {
			return 0
		}

		const orderItem = basket.orderItems[basket.values.selectedOrderItemIndex]

		if (!orderItem) {
			return 0
		}

		const productIndex: number = orderItem.products.findIndex(
			(x) => x.productId === product.id && x.serviceId === selectedServiceId,
		)
		if (productIndex > -1) {
			return orderItem.products[productIndex]?.amount || 0
		}

		return 0
	}

	function productSelectionElement(product: WasteProductDefinitionWithPrice, selectedServiceId: string) {
		if (product.service == null) {
			return "Välj en tjänst först"
		}

		if (product.service.unit === ProductServiceUnit.Piece) {
			return productAmountSelectionElement(selectedCategoryName, selectedServiceId, product)
		}
		return productNonDiscreteSelectionElement(product)
	}

	function productAmountSelectionElement(category: string, serviceId: string, product: ProductDefinition): JSX.Element {
		const defaultValue = incrementorDefaultValue(product)
		return (
			<ExpandableIncrementor
				key={category + serviceId + product.id}
				name={category + serviceId + product.id + "_incrementor"}
				defaultValue={0}
				currentValue={Object.assign({}, { value: defaultValue, show: defaultValue > 0 })}
				max={2000}
				min={0}
				onChange={(name, value, buttonClicked, currentValue) => {
					if (value > 0) {
						onProductIncrementorChange(
							buttonClicked,
							product,
							value,
							currentValue,
							selectedCategoryName,
							serviceId,
						)
					}
				}}
				onBlur={(value) => {
					if (value === 0) {
						onProductIncrementorChange("removeClick", product, 0, 1, selectedCategoryName, serviceId)
					}
				}}
			/>
		)
	}

	function productNonDiscreteSelectionElement(product: ProductDefinitionWithPrice) {
		return (
			<button
				className={style.addProductButton}
				onClick={(event) => {
					event.preventDefault()
					event.stopPropagation()
					onProductClick(selectedCategoryName, product)
				}}>
				Välj
			</button>
		)
	}

	function renderTagsCell() {
		if (_.isEmpty(tags)) {
			return null
		}

		return (
			<HorizontalScrollBox cellClassName={style.subCategoryCell} key={`tags-${selectedCategory?.id}`}>
				{tags.map((tag) => {
					let isSelected = tag === selectedTag
					return (
						<div
							key={`tags-item-${selectedCategory?.id}-${tag}`}
							className={cls(style.sectionBox, style.subCategoryCard, {
								[style.selectedSectionBox]: isSelected,
							})}
							aria-selected={isSelected}
							onClick={() => setSelectedTag(isSelected ? null : tag)}>
							<span>{tag}</span>
						</div>
					)
				})}
			</HorizontalScrollBox>
		)
	}

	/**
	 * Render Sub-Categories as "filter" options, similar to tags.
	 */
	function renderSubCategoriesCell() {
		if (_.isEmpty(subCategories)) {
			return null
		}

		return (
			<HorizontalScrollBox cellClassName={style.subCategoryCell} key={`subCat-${selectedCategory?.id}`}>
				{subCategories.map((subCategory) => {
					const isSelected = subCategory.id === selectedSubCategory?.id
					return (
						<div
							key={`${selectedCategoryName}-${subCategory.id}`}
							className={cls(style.sectionBox, style.subCategoryCard, {
								[style.selectedSectionBox]: isSelected,
							})}
							aria-selected={isSelected}
							onClick={() => setSelectedSubCategory(isSelected ? null : subCategory.title)}>
							<span>{subCategory.title}</span>
						</div>
					)
				})}
			</HorizontalScrollBox>
		)
	}

	/**
	 * Render Sub-Categories as if they were the main categories.
	 */
	function renderSubCategoriesAsMainCategorySection() {
		if (_.isEmpty(subCategories)) {
			return null
		}

		return (
			<CategorySelectionSection
				categories={subCategories.map(({ categoryImages, id, title }) => {
					let isSelected = id === selectedSubCategory?.id
					return {
						title: title,
						categoryImages: categoryImages,
						key: `${selectedCategoryName}-${id}`,
						selected: isSelected,
						order: 1,
					}
				})}
				onSelected={(category) => setSelectedSubCategory(category.selected ? null : category.title)}
			/>
		)
	}

	return (
		<>
			{onlySingleCategoryExists ? renderSubCategoriesAsMainCategorySection() : null}
			<div className={style.section}>
				<div className={style.sectionH1}>
					<MbactH1>{selectedCategory?.name || "Produkter"}</MbactH1>
				</div>
				{renderTagsCell()}
				{!onlySingleCategoryExists ? renderSubCategoriesCell() : null}
				{cardElements()}
			</div>
		</>
	)
}

function useSubCategories(
	allAvailableProducts: ProductDefinition[] | null,
	selectedCategory: ProductCategoryInstance | null,
) {
	const subCategoryIds = _.uniq(allAvailableProducts?.flatMap((pd) => pd.subCategories ?? []))
	const subCategories = useMemo(() => {
		return (
			selectedCategory?.subCategories?.filter((it) => {
				return subCategoryIds.indexOf(it.id) >= 0
			}) ?? []
		)
	}, [selectedCategory?.subCategories, subCategoryIds])
	const [selectedSubCategoryTitle, setSelectedSubCategoryTitle] = useSearchParamState<null>("subCat", null)
	const selectedSubCategory = subCategories?.find((it) => it.title === selectedSubCategoryTitle)

	useEffect(() => {
		/**
		 * HACK, the incoming props are a bit unstable, thus multiple re-renders happens before the data is stable,
		 * by deferring the clearing of a selected subcategory for at bit, we hope the data has stabilized.
		 */
		if (
			selectedSubCategoryTitle != null &&
			subCategories != null &&
			subCategories.find((it) => it.title === selectedSubCategoryTitle) == null
		) {
			let task = window.setTimeout(() => {
				//Clear selected subcategory if it is unavailable for the current products
				setSelectedSubCategoryTitle(null, true)
			}, 20)
			return () => {
				window.clearTimeout(task)
			}
		}
		return
	}, [selectedSubCategoryTitle, setSelectedSubCategoryTitle, subCategories])

	const setSelectedSubCategory = useCallback(
		(selectedSubCategoryTitle: string | null) => {
			setSelectedSubCategoryTitle(selectedSubCategoryTitle)
		},
		[setSelectedSubCategoryTitle],
	)

	return { subCategories, setSelectedSubCategory, selectedSubCategory }
}

function useTags(allAvailableProducts: ProductDefinition[] | null) {
	const tags = _.uniq(allAvailableProducts?.flatMap((pd) => pd.tags ?? []))
	const [selectedTag, setSelectedTag] = useSearchParamState<null>("tag", null)

	useEffect(() => {
		/**
		 * HACK, the incoming props are a bit unstable, thus multiple re-renders happens before the data is stable,
		 * by deferring the clearing of a selected tag for at bit, we hope the data has stabilized.
		 */
		const task = window.setTimeout(() => {
			if (!_.isEmpty(allAvailableProducts) && selectedTag != null && tags.indexOf(selectedTag) < 0) {
				//Clear selected tag if it is unavailable for the current products
				setSelectedTag(null, true)
			}
		})
		return () => {
			window.clearTimeout(task)
		}
	})

	return { tags, selectedTag, setSelectedTag }
}

function filterProductDefsOnTagAndSubCategory(
	productDefs: ProductDefinitionWithPrice[] | null,
	selectedTag: string | null,
	selectedSubCategory: SubCategory | undefined,
) {
	if (productDefs == null) {
		return null
	}

	return productDefs.filter((pd) => {
		return filterOnSubCategory(pd, selectedSubCategory) && filterOnTag(pd, selectedTag)
	})
}

function filterOnTag(pd: ProductDefinition, selectedTag: string | null) {
	if (selectedTag == null) {
		return true
	}
	if (pd.tags == null) {
		return false
	}

	return pd.tags.indexOf(selectedTag) >= 0
}

function filterOnSubCategory(pd: ProductDefinition, selectedSubCategory: SubCategory | undefined) {
	if (selectedSubCategory == null) {
		return true
	}
	if (pd.subCategories == null) {
		return false
	}

	return pd.subCategories.find((it) => it === selectedSubCategory.id) != null
}
