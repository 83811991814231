import { Features } from "Client/FeatureTypes"
import { exhaustive } from "exhaustive"
import { isArray, sortBy } from "lodash"
import { Widget } from "Shared/Widget/Widgets"
import { getLogger } from "../Logging/getLogger"
import { PolygonTransportZone } from "../Orders/ProjectInputModule/ProjectInputModule"
import { WasteId, WasteType } from "../ProductDefinitions"
import {
	ClientBranding,
	ClientInfo,
	ClientModes,
	DynamicClientAgreements,
	GetClientResponse,
	Links,
} from "./GetClientResponse"
import {
	BrandOverrides,
	CategoryImageDeprecated,
	CategoryImages,
	DateSlotId,
	GetGoodsProductSelectionConfig,
	GetWasteProductSelectionConfig,
	GoodsProductDefinition,
	PackagingMethod,
	PackagingMethodId,
	ProductCategoryId,
	ProductDateSlot,
	ProductDefinition,
	ProductDefinitionsByCategories,
	ProductService,
	ProductTimeSlot,
	ProductTransportation,
	SubCategory,
	TimeSlotId,
	TransportationId,
	WasteProductDefinition,
} from "./ProductDefinitionsByCategories"

const logger = getLogger("ClientInstance")

export function clientInstanceOf(GetClientResponse: GetClientResponse): ClientInstance {
	return new ClientInstance(
		GetClientResponse.client.identifier,
		GetClientResponse.client.branding,
		GetClientResponse.client.clientInfo,
		GetClientResponse.client.modes,
		GetClientResponse.links,
		GetClientResponse.dynamicAgreements,
		GetClientResponse.features,
		GetClientResponse.welcomeWidget,
		GetClientResponse.productDefinitions,
	)
}

export class ClientInstance {
	readonly categories: { [categoryName: string]: ProductCategoryInstance } = {}
	readonly categoryKeys: string[] = []

	readonly possibleWasteTypes: { [key: WasteId]: WasteType } = {}
	readonly possibleTimeSlots: { [key: TimeSlotId]: ProductTimeSlot } = {}
	readonly possibleDateSlots: { [key: DateSlotId]: ProductDateSlot } = {}
	readonly possibleTransportations: { [key: TransportationId]: ProductTransportation } = {}
	readonly transportZones: { [transportZoneId: string]: PolygonTransportZone } = {}
	readonly possiblePackagingMethods: { [key: PackagingMethodId]: PackagingMethod } = {}
	public get transportZonesSorted(): PolygonTransportZone[] {
		return this._transportZonesSorted
	}

	private _transportZonesSorted: PolygonTransportZone[] = []
	constructor(
		public readonly identifier: string,
		public readonly branding: ClientBranding,
		public readonly clientInfo: ClientInfo,
		public readonly modes: ClientModes[],
		public readonly links: Links | undefined,
		public readonly dynamicAgreements: DynamicClientAgreements | undefined,
		public readonly features: Features,
		public readonly welcomeWidget: Widget | undefined,
		private readonly productsFromBackend: ProductDefinitionsByCategories,
	) {
		this.possibleWasteTypes = productsFromBackend.possibleWasteTypes
		this.possibleTimeSlots = productsFromBackend.possibleTimeSlots
		this.possibleDateSlots = productsFromBackend.possibleDateSlots || {}
		this.possibleTransportations = productsFromBackend.possibleTransportations
		this.possiblePackagingMethods = productsFromBackend.possiblePackagingMethods

		productsFromBackend.categories.forEach((category) => {
			const typedCategory = exhaustive.tag(category, "type", {
				WasteCategory: (category): WasteProductCategoryInstance => {
					let services: { [serviceId: string]: ProductService } = {}
					category.services.forEach((x) => {
						services[x.id] = x
					})

					let wasteCategory: WasteProductCategoryInstance = {
						type: "WasteCategory",
						id: category.id,
						name: category.name,
						image: category.image,
						categoryImages: category.categoryImages,
						subCategories: category.subCategories,
						services: services,
						products: {}, //Will complement this later
						brandColorOverrides: category.brandColorOverrides,
						logoOverride: category.logoOverride,
						serviceIds: Object.keys(services),
						order: category.order,
						productSelectionConfig: category.productSelectionConfig,
					}

					wasteCategory.products = Object.fromEntries(
						category.products.map((product) => {
							//HACK, create a double linked relation for type safe access later
							product.category = wasteCategory
							product.categoryName = wasteCategory.name
							return [product.id, product]
						}),
					)

					return wasteCategory
				},
				GoodsCategory: (category): GoodsProductCategoryInstance => {
					let goodsCategory: GoodsProductCategoryInstance = {
						type: "GoodsCategory",
						id: category.id,
						name: category.name,
						image: category.image,
						categoryImages: category.categoryImages,
						subCategories: category.subCategories,
						products: {}, //Will complement this later
						order: category.order,
						productSelectionConfig: category.productSelectionConfig,
					}

					goodsCategory.products = Object.fromEntries(
						category.products.map((product) => {
							//HACK, create a double linked relation for type safe access later
							product.category = goodsCategory
							product.categoryName = goodsCategory.name
							return [product.id, product]
						}),
					)

					return goodsCategory
				},
				_: () => {
					logger.error(
						"Received an unknown Category Type, this version of the App is probably to old! ignoring the data:",
						category,
					)
					return null
				},
			})

			if (typedCategory != null) {
				this.categories[typedCategory.name] = typedCategory
			}
		})

		this.categoryKeys = sortBy(Object.keys(this.categories), (x) => {
			return this.categories[x]?.order || 1
		})
	}

	public hasMode(mode: ClientModes) {
		return this.modes.find((m) => m === mode) !== undefined
	}

	public isDemo() {
		return this.hasMode(ClientModes.Demo)
	}

	public getLogoUrl(): string | undefined {
		return this.branding.images.logos[0]
	}

	public getLogoUrlWithCategoryOverride(categoryName?: string): string | null {
		if (categoryName) {
			const category = this.categories[categoryName]
			if (category) {
				const override = exhaustive.tag(category, "type", {
					WasteCategory: (category) => {
						return category.logoOverride || null
					},
					GoodsCategory: () => null, //No logo override
					_: () => null, //Unknown type, move along, nothing to see
				})
				if (override) {
					return override
				}
			}
		}
		return this.branding.images.logos[0] || null
	}

	public getBrandColorOverrideOrNull(categoryName?: string): BrandOverrides | null {
		if (!categoryName) {
			return null
		}

		const category = this.categories[categoryName]
		if (!category) {
			return null
		}

		return exhaustive.tag(category, "type", {
			WasteCategory: (category) => {
				return category.brandColorOverrides || null
			},
			GoodsCategory: () => null, //No override
			_: () => null, //Unknown type, move along, nothing to see
		})
	}

	public findCategoryByName(categoryName: string | null | undefined): ProductCategoryInstance | null {
		if (!categoryName) return null
		let [, category] = Object.entries(this.categories).find(([, value]) => value.name === categoryName) ?? []
		return category ?? null
	}

	public setTransportZones(zones: PolygonTransportZone[]): void {
		if (!isArray(zones)) {
			return
		}

		zones.forEach((zone) => {
			this.transportZones[zone.id] = zone
		})

		this._transportZonesSorted = sortBy(zones, "priority")
	}

	public findProductById(productId: string): ProductDefinition | null {
		for (const category of Object.values(this.categories)) {
			let ret: ProductDefinition | null = category.products[productId] || null
			if (ret) {
				return ret
			}
		}

		return null
	}

	public firstCategory(): ProductCategoryInstance | null {
		const key = this.categoryKeys[0]

		if (key) {
			return this.categories[key] || null
		}

		return null
	}
}

export type ProductCategoryInstance = WasteProductCategoryInstance | GoodsProductCategoryInstance

export type WasteProductCategoryInstance = {
	type: "WasteCategory"
	id: ProductCategoryId
	name: string
	image: CategoryImageDeprecated | undefined
	categoryImages: CategoryImages | undefined
	subCategories: SubCategory[] | undefined
	services: { [serviceId: string]: ProductService }
	products: { [productId: string]: WasteProductDefinition }
	brandColorOverrides: BrandOverrides
	logoOverride?: string
	serviceIds: string[]
	productSelectionConfig?: GetWasteProductSelectionConfig
	order: number
}

export type GoodsProductCategoryInstance = {
	type: "GoodsCategory"
	id: ProductCategoryId
	name: string
	image: CategoryImageDeprecated | undefined
	categoryImages: CategoryImages | undefined
	subCategories: SubCategory[] | undefined
	products: { [productId: string]: GoodsProductDefinition }
	productSelectionConfig?: GetGoodsProductSelectionConfig
	order: number
}
