import { fixLottieColor } from "./Helpers"

import ComfortableJson from "./comfortable.json"
import DiggingJson from "./digging.json"
import HappyCustomerJson from "./happyCustomer.json"
import OopsJson from "./Oops.json"
import OrderCompletedJson from "./OrderCompleted.json"
import PainterJson from "./painter.json"
import RocketJson from "./rocket.json"
import RunnningJson from "./runnning.json"
import SafetyJson from "./safety.json"
import SentJson from "./Sent.json"
import SkrappyLoadingJson from "./skrappyLoading.json"
import SuperheroJson from "./superhero.json"
import ThinkingJson from "./thinking.json"
import UnderConstructionJson from "./underConstruction.json"
import WeFixJson from "./weFix.json"

import Lottie, { LottieComponentProps } from "lottie-react"

type LottieProps = Partial<LottieComponentProps> & {
	color?: string
}

export const Comfortable = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(ComfortableJson, props.color)} autoPlay loop />
)
export const Digging = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(DiggingJson, props.color)} autoPlay loop />
)
export const HappyCustomer = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(HappyCustomerJson, props.color)} autoPlay loop />
)
export const Oops = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(OopsJson, props.color)} autoPlay loop />
)
export const OrderCompleted = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(OrderCompletedJson, props.color)} autoPlay loop />
)
export const Painter = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(PainterJson, props.color)} autoPlay loop />
)
export const Rocket = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(RocketJson, props.color)} autoPlay loop />
)
export const Runnning = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(RunnningJson, props.color)} autoPlay loop />
)
export const Safety = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(SafetyJson, props.color)} autoPlay loop />
)
export const Sent = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(SentJson, props.color)} autoPlay loop />
)
export const SkrappyLoading = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(SkrappyLoadingJson, props.color)} autoPlay loop />
)
export const Superhero = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(SuperheroJson, props.color)} autoPlay loop />
)
export const Thinking = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(ThinkingJson, props.color)} autoPlay loop />
)
export const UnderConstruction = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(UnderConstructionJson, props.color)} autoPlay loop />
)
export const WeFix = (props: LottieProps) => (
	<Lottie {...props} animationData={fixLottieColor(WeFixJson, props.color)} autoPlay loop />
)

export function DemoAllLottieAnimations(testColor?: string) {
	return (
		<>
			<div style={{ width: "200px", float: "left" }}>
				Comfortable: <Comfortable color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Digging: <Digging color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				HappyCustomer: <HappyCustomer color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Oops: <Oops color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				OrderCompleted: <OrderCompleted color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Painter: <Painter color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Rocket: <Rocket color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Runnning: <Runnning color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Safety: <Safety color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Sent: <Sent color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				SkrappyLoading: <SkrappyLoading color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Superhero: <Superhero color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				Thinking: <Thinking color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				UnderConstruction: <UnderConstruction color={testColor} />
			</div>
			<div style={{ width: "200px", float: "left" }}>
				WeFix: <WeFix color={testColor} />
			</div>
		</>
	)
}
