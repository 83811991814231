import {
	faEnvelope,
	faEye,
	faEyeSlash,
	faLock,
	faPhone,
	faSignature,
	faSpinner,
	faUserPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { exhaustive } from "exhaustive"
import { FC, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import { z } from "zod"
import { LoginRequest } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerSelfRegistrationMode } from "../../Client/FeatureTypes"
import { useNavigator } from "../../Navigator/useNavigator"
import { AccentButton, FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { invalidPhoneNumberMessage, validatePhoneNumberForSweden } from "../../Orders/Components/Form/constants"
import { SbtH1 } from "../../Orders/Components/Text/SbtH1/SbtH1"
import { SbtH5 } from "../../Orders/Components/Text/SbtH5/SbtH5"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import style from "./Login.module.css"

const LoginInfo = z.object({
	username: z.string().optional(),
	email: z.string().optional(),
	phone: z.string().optional(),
	password: z.string().min(1),
})

const UsernameLogin = z.object({
	username: z.string().min(1),
	password: z.string().min(1),
})

const EmailLogin = z.object({
	email: z.string().min(1).email("Felaktigt format på e-post"),
	password: z.string().min(1),
})

const PhoneLogin = z.object({
	phone: z
		.string()
		.min(3, invalidPhoneNumberMessage)
		.refine(validatePhoneNumberForSweden, { message: invalidPhoneNumberMessage }),
	password: z.string().min(1),
})

type LoginInfoType = z.input<typeof LoginInfo>

type IdentifierType = "username" | "email" | "phone"

type LoginFormProps = {
	onClose: (data?: string) => void
	onShowResetPassword: (emailSuggestion?: string) => void
}

export const LoginForm: FC<LoginFormProps> = ({ onClose, onShowResetPassword }) => {
	const auth = useAuth()
	const client = useClient()
	const navigator = useNavigator()
	const location = useLocation()

	const [identifierType, setIdentifierType] = useState<IdentifierType>("email")
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [showEnteredPassword, setShowEnteredPassword] = useState<boolean>(false)

	const {
		register,
		handleSubmit,
		clearErrors,
		setError,
		getValues,
		formState: { errors },
	} = useForm<LoginInfoType>({
		resolver: async (data, context, options) => {
			if (identifierType === "username") {
				return zodResolver(UsernameLogin)(data, context, options)
			} else if (identifierType === "email") {
				return zodResolver(EmailLogin)(data, context, options)
			} else {
				return zodResolver(PhoneLogin)(data, context, options)
			}
		},
	})

	const onSubmit: SubmitHandler<LoginInfoType> = async (data) => {
		setIsSubmitting(true)
		if (!data.username && !data.email && !data.phone) {
			return
		}

		const reqObj: LoginRequest = {
			clientIdentifier: client.identifier,
			username: data.username?.toLocaleLowerCase(),
			email: data.email?.toLocaleLowerCase(),
			phoneNumber: data.phone,
			password: data.password,
		}

		auth.login(reqObj).then(
			() => {
				setIsSubmitting(false)
				onClose("loggedIn")
			},
			(err) => {
				setIsSubmitting(false)
				if (err.status === 401) {
					setError("password", { type: "badLoginDetails", message: "Felaktiga uppgifter, försök igen" })
				} else {
					setError("password", { type: "badLoginResponse", message: "Något gick fel, försök igen" })
				}
			},
		)
	}

	useEffect(() => {
		clearErrors()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifierType])

	return (
		<>
			<form className={style.wrapper} onSubmit={handleSubmit(onSubmit)}>
				<SbtH1 className={style.title}>Logga in</SbtH1>
				<SbtH5>
					{identifierType === "username"
						? "Logga med ditt användarnamn och ditt lösenord"
						: identifierType === "email"
						? "Logga med din e-postadress och ditt lösenord"
						: "Logga med ditt telefonnummer och ditt lösenord"}
				</SbtH5>
				<div className={style.fields}>
					{identifierType === "username" ? (
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faSignature} />
								</div>
								<input
									className={style.input}
									{...register("username")}
									placeholder="Fyll i ditt användarnamn"
								/>
							</div>
							<SbtRHFError error={errors.username} />
						</label>
					) : null}
					{identifierType === "email" ? (
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faEnvelope} />
								</div>
								<input
									className={style.input}
									{...register("email")}
									placeholder="Fyll i din e-postadress"
								/>
							</div>
							<SbtRHFError error={errors.email} />
						</label>
					) : null}
					{identifierType === "phone" ? (
						<label>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faPhone} />
								</div>
								<input
									className={style.input}
									{...register("phone")}
									placeholder="Fyll i ditt telefonnummer"
								/>
							</div>
							<SbtRHFError error={errors.phone} />
						</label>
					) : null}
					<label>
						<div className={style.inputWrapper}>
							<div>
								<FontAwesomeIcon icon={faLock} />
							</div>
							<input
								className={style.input}
								type={showEnteredPassword ? "text" : "password"}
								{...register("password")}
								placeholder="Fyll i ditt lösenord"
							/>
							<FontAwesomeIcon
								style={{ marginLeft: "-33px", cursor: "pointer" }}
								icon={showEnteredPassword ? faEyeSlash : faEye}
								onClick={() => {
									setShowEnteredPassword(!showEnteredPassword)
								}}
							/>
						</div>

						<SbtRHFError error={errors.password} />
					</label>
					<div
						className={style.forgotPassword}
						onClick={() => {
							let email = getValues("email")
							onShowResetPassword(email)
						}}>
						Har du glömt ditt lösenord?
					</div>
				</div>
				<div className={style.bottomSection}>
					<FinalizeButton disabled={isSubmitting} type="submit">
						Logga in{" "}
						{isSubmitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
					{client.features.consumerSelfRegistrationSettings.mode !== ConsumerSelfRegistrationMode.Deny ? (
						<>
							<p
								className={style.infoChangeType}
								style={{
									marginTop: "25px",
								}}>
								Inget konto?
							</p>
							<AccentButton
								disabled={isSubmitting}
								onClick={() => {
									if (location.pathname.startsWith(`/${client.identifier}/new-customer`)) {
										onClose()
									} else {
										navigator.open("new-customer")
									}
								}}>
								Bli kund <FontAwesomeIcon icon={faUserPlus} />
							</AccentButton>
						</>
					) : null}

					<p className={style.infoChangeType}>
						{exhaustive(identifierType, {
							email: () => "Ingen e-postadress?",
							username: () => "Inget användarnamn?",
							phone: () => "Inget telefonnummer?",
						})}
					</p>
					{identifierType !== "username" ? (
						<AccentButton
							style={{ marginBottom: "20px" }}
							disabled={isSubmitting}
							onClick={() => {
								setIdentifierType("username")
							}}>
							Logga in med användarnamn
						</AccentButton>
					) : null}
					{identifierType !== "email" ? (
						<AccentButton
							style={{ marginBottom: identifierType === "phone" ? undefined : "20px" }}
							disabled={isSubmitting}
							onClick={() => {
								setIdentifierType("email")
							}}>
							Logga in med e-post
						</AccentButton>
					) : null}
					{identifierType !== "phone" ? (
						<AccentButton
							disabled={isSubmitting}
							onClick={() => {
								setIdentifierType("phone")
							}}>
							Logga in med telefonnummer
						</AccentButton>
					) : null}
				</div>
			</form>
		</>
	)
}
