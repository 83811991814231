import { AbsolutCentered } from "AbsolutCentered/AbsolutCentered"
import { MeResponseAccountType } from "Auth/Auth.types"
import { GetUserInvitation, SenderDetails } from "CustomerPortal/UserInvitations/UsersInvitations"
import { Loader } from "Loader/Loader"
import { isUndefined } from "lodash"
import { API, ServerError } from "network/API"
import { AccentButton, FinalizeButton } from "Orders/Components/Form/Buttons/Buttons"
import { removeModalOpenClass } from "Orders/Components/ModulePopup/ModulePopup"
import { SbtH2 } from "Orders/Components/Text/SbtH2/SbtH2"
import { SbtH4 } from "Orders/Components/Text/SbtH4/SbtH4"
import { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAuth } from "../../../Auth/AuthContext"
import { LoginDialog } from "../../Login/LoginDialog"
import { HandleInvitationState } from "../HandleUserInvitation"
import style from "./../HandleUserInvitation.module.css"
import { PublicConsumer } from "./HandleUserInvitationLinkAccount"
import { compileDisplayName, PresentConsumer } from "./PresentConsumer"

type AcceptOptionsProps = { invitation: GetUserInvitation; setState: (state: HandleInvitationState) => void }

export const AcceptOptions: FC<AcceptOptionsProps> = ({ invitation, setState }) => {
	const { clientName } = useParams()
	const auth = useAuth()
	const [publicConsumer, setPublicConsumer] = useState<PublicConsumer | undefined>(undefined)

	useEffect(() => {
		API.getWithRetries<PublicConsumer>(
			`/customer-portal/consumers-v1/${clientName}/read/${invitation.consumerRef}`,
			true,
		)
			.then((res) => {
				setPublicConsumer(res)
			})
			.catch((error: ServerError<unknown>) => {})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	if (isUndefined(publicConsumer)) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	} else {
		return (
			<div>
				<SbtH2 styles={{ marginBottom: "1rem" }}>Välkommen!</SbtH2>
				<div style={{ textAlign: "left" }}>{getSenderDetails(invitation.senderDetails, publicConsumer)}</div>
				<div>{getMessageSection(invitation)}</div>
				{auth.Me && auth.Me?.type === MeResponseAccountType.Consumer ? (
					<div style={{ marginTop: "2rem" }}>
						<SbtH4>Vi ser att du redan är inloggad</SbtH4>
						<PresentConsumer
							name={compileDisplayName(auth.Me.firstName, auth.Me.lastName)}
							details={[auth.Me.email, auth.Me.phoneNumber]}
							profilePic={auth.Me.profilePicture}
							noBg={true}
						/>
						<div style={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
							Eftersom att du redan har ett konto, så kan du koppla samma inloggningsuppgifter till "
							<strong>{invitation.senderDetails.consumerName}</strong>"
						</div>
						<AccentButton
							onClick={() => setState(HandleInvitationState.LinkExistingUser)}
							style={{ marginBottom: "1rem" }}>
							Koppla ditt befintliga konto
						</AccentButton>
					</div>
				) : null}
				<div style={{ marginTop: "1rem" }}>
					<SbtH4>Vill du skapa ett konto?</SbtH4>
					<FinalizeButton onClick={() => setState(HandleInvitationState.CreatNewUser)}>
						Skapa ett konto
					</FinalizeButton>
				</div>
				<div style={{ textAlign: "left", marginTop: "1rem" }}>
					<SbtH4>Har du ett annat konto?</SbtH4>
				</div>
				<div style={{ marginTop: "1rem" }}>
					<LoginModalWrapper setState={setState} />
				</div>
			</div>
		)
	}
}

type LoginModalWrapperProps = { setState: (state: HandleInvitationState) => void }
const LoginModalWrapper: FC<LoginModalWrapperProps> = ({ setState }) => {
	const [showLogin, setShowLogin] = useState(false)

	return (
		<>
			<AccentButton onClick={() => setShowLogin(true)}>Logga in för att koppla det kontot</AccentButton>
			{showLogin ? (
				<LoginDialog
					onClose={(reason) => {
						setShowLogin(false)
						removeModalOpenClass()

						if (reason === "loggedIn") {
							setState(HandleInvitationState.LinkExistingUser)
						}
					}}
				/>
			) : null}
		</>
	)
}

function getSenderDetails(sender: SenderDetails, publicConsumer: PublicConsumer) {
	const firstName = sender.firstName
	const lastName = sender.lastName
	if (sender.isClientUser) {
		return (
			<div>
				<div>Du har blivit inbjuden att skapa ett konto hos:</div>
				<PresentConsumer
					name={publicConsumer.name}
					details={[publicConsumer.identifier]}
					profilePic={publicConsumer.profilePicture}
					noBg={true}></PresentConsumer>
			</div>
		)
	} else {
		if (isUndefined(firstName) && isUndefined(lastName)) {
			return (
				<div style={{ textAlign: "left" }}>
					Du har blivit inbjuden att skapa en användare under "<strong>{sender.consumerName}</strong>"
				</div>
			)
		} else {
			return (
				<div style={{ textAlign: "left" }}>
					<strong>
						"{!isUndefined(firstName) ? firstName + " " : null}
						{!isUndefined(lastName) ? lastName : null}"
					</strong>{" "}
					har bjudit in dig att skapa ett konto hos oss.
				</div>
			)
		}
	}
}

function getMessageSection(invitation: GetUserInvitation) {
	if (!isUndefined(invitation.requestDetails.message) && invitation.requestDetails.message !== "") {
		if (invitation.senderDetails.isClientUser) {
			return (
				<div style={{ marginBottom: "1rem", marginTop: "1rem", textAlign: "left" }}>
					<span>Meddelande</span>
					<div className={style.senderMessageArea}>{invitation.requestDetails.message}</div>
				</div>
			)
		}
		return (
			<div style={{ marginBottom: "1rem", marginTop: "1rem", textAlign: "left" }}>
				<span>
					Meddelande från: <strong>"{invitation.senderDetails.consumerName}"</strong>
				</span>
				<div className={style.senderMessageArea}>{invitation.requestDetails.message}</div>
			</div>
		)
	} else {
		return null
	}
}
