import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { Key, useEffect, useRef, useState } from "react"
import { cls } from "../../../Shared/cls"
import style from "./HorizontalScollBox.module.css"

type Props = {
	className?: string
	cellClassName?: string
	scrollReduceFactor?: number
	key: Key
	children: React.ReactNode
	wrapperElementGet?: (element: HTMLDivElement) => void
	scrollButtonClassName?: string
}

/**
 * Create a div that allows horizontal scrolling with regular mouse scroll and has next and prev buttons.
 * On first load of the component, the child element with ariaSelected = true is .scrollIntoView()
 */
export function HorizontalScrollBox({
	children,
	className,
	cellClassName,
	scrollReduceFactor = 4,
	wrapperElementGet,
	scrollButtonClassName,
}: Props) {
	const cellContainer = useRef<HTMLDivElement | null>(null)
	const lastChildren = useRef<React.ReactNode | null>(null)
	const scrollIntoViewOnFirstLoad = useRef(false)
	const [showPrevArrow, setShowPrevArrow] = useState(false)
	const [showNextArrow, setShowNextArrow] = useState(false)

	const wrapperRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (wrapperElementGet && wrapperRef.current) {
			wrapperElementGet(wrapperRef.current)
		}
	}, [wrapperRef, wrapperElementGet])

	useEffect(() => {
		if (!cellContainer.current) {
			return
		}

		const container = cellContainer.current

		let wheelEventListener = (evt: WheelEvent) => {
			if (container != null && (showPrevArrow || showNextArrow)) {
				evt.preventDefault()
				let scrollLeft = (evt.deltaY + evt.deltaX) / scrollReduceFactor
				container.scrollLeft += scrollLeft
			}
		}
		container.addEventListener("wheel", wheelEventListener)
		return () => {
			container?.removeEventListener("wheel", wheelEventListener)
		}
	}, [cellContainer, scrollReduceFactor, showNextArrow, showPrevArrow])

	useEffect(() => {
		if (!cellContainer.current) {
			return
		}

		//We don't use this more than we need children as a dep for this useEffect, so it feels a bit quirky still
		lastChildren.current = children

		const containerChildren = cellContainer.current.children
		if (containerChildren.length <= 0) {
			return
		}

		const firstElement = containerChildren[0]
		const lastElement = containerChildren[containerChildren.length - 1]

		const firstElementObserver = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					setShowPrevArrow(entry.intersectionRatio <= 0.9)
				})
			},
			{ threshold: [0.9, 0.91], root: cellContainer.current },
		)
		if (firstElement) {
			firstElementObserver.observe(firstElement)
		}

		const lastElementObserver = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					setShowNextArrow(entry.intersectionRatio <= 0.9)
				})
			},
			{ threshold: [0.9, 0.91], root: cellContainer.current },
		)
		if (lastElement) {
			lastElementObserver.observe(lastElement)
		}

		let childrenArray = Array.from(containerChildren)
		for (const [, childA] of childrenArray.entries()) {
			if (!scrollIntoViewOnFirstLoad.current && childA.ariaSelected === "true" && cellContainer.current) {
				scrollIntoViewOnFirstLoad.current = true
				childA.scrollIntoView({ behavior: "smooth", block: "nearest" })
			}
		}

		return () => {
			firstElementObserver.disconnect()
			lastElementObserver.disconnect()
		}
	}, [cellContainer, scrollReduceFactor, children])

	function scroll(forward: boolean) {
		if (cellContainer.current && cellContainer.current.children) {
			const cellContainerWidth = cellContainer.current.clientWidth
			const width = cellContainerWidth / 2
			const amount = forward ? width : width * -1

			cellContainer.current.scrollTo({ behavior: "smooth", left: cellContainer.current.scrollLeft + amount })
		}
	}

	return (
		<div className={cls(style.wrapper, className)} ref={wrapperRef}>
			<div
				className={cls(
					style.scrollButton,
					style.scrollButtonPrevious,
					{
						[style.show]: showPrevArrow,
					},
					scrollButtonClassName,
				)}
				onClick={() => {
					scroll(false)
				}}>
				<FontAwesomeIcon icon={faAngleLeft} />
			</div>

			<div ref={cellContainer} className={cls(style.cellContainer, cellClassName)}>
				{children}
			</div>

			<div
				className={cls(
					style.scrollButton,
					style.scrollButtonNext,
					{ [style.show]: showNextArrow },
					scrollButtonClassName,
				)}
				onClick={() => {
					scroll(true)
				}}>
				<FontAwesomeIcon icon={faAngleRight} />
			</div>
		</div>
	)
}
