import { PhoneNumberUtil } from "google-libphonenumber"
import { isString } from "lodash"
import { z } from "zod"

export const invalidPhoneNumberMessage: string = "Felaktigt format på telefonnummer"
export const invalidPersonNumberMessage: string = "Felaktigt format på personnummret (ÅÅÅÅMMDD-XXXX)"
export const invalidOrganizationNumberMessage: string = "Felaktigt format på organisationsnummret"

export const personNumberRegex = new RegExp("^(19|20)[0-9]{6}-[0-9]{4}$")
export const organizationNumberRegex = new RegExp("^[0-9]{6}-[0-9]{4}$")

export const optionalTextInput = (schema: z.ZodString, msg: string) =>
	z.union([z.string(), z.undefined()]).refine((val) => !val || schema.safeParse(val).success, msg)

/**
 * Checks whether a given string is a valid swedish phone number
 * @param data
 */
export const validatePhoneNumberForSweden = (data: string) => {
	// Have to use try catch because the lib throws an exception in some cases when parsing instead of just returning false...
	try {
		if (isString(data)) {
			const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance()
			return phoneUtil.isValidNumberForRegion(phoneUtil.parse(data, "SE"), "SE")
		}
		return false
	} catch (e) {
		return false
	}
}

/**
 * Takes a number, and if it's a valid swedish number, formats it according to swedish standards (as decided by google)
 *
 * If the number isn't a valid swedish number, it's returned as is
 * @param phoneNumber
 */
export const formatPhoneNumberForSweden = (phoneNumber?: string) => {
	if (!phoneNumber) {
		return ""
	}

	if (validatePhoneNumberForSweden(phoneNumber)) {
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance()
		return phoneUtil.formatInOriginalFormat(phoneUtil.parse(phoneNumber, "SE"), "SE")
	}

	return phoneNumber
}
