import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { DateTime } from "luxon"
import { FC, useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { Loader } from "../../Loader/Loader"
import { useNavigator } from "../../Navigator/useNavigator"
import { API } from "../../network/API"
import { AccentButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { parseNumberSafe } from "../../Orders/Helpers"
import { rowsPerPageSection, SkrappyTable, TableColumn, tablePagingSection } from "../../Shared/SkrappyTable/SkrappyTable"
import { useBrandedLocalStorage } from "../../Shared/useBrandedLocalStorage"
import { Paging, setPageAndFilterInUrl } from "../CustomerPortalOrders/CustomerPortalOrders"
import style from "./NotificationsList.module.css"

export enum NotificationSendStatus {
	Pending = "Pending",
	Sent = "Sent",
}

type GetNotificationReadBy = {
	userRef: string
	readAt: number
}

export enum NotificationReadStatus {
	Unread = "Unread",
	Read = "Read",
}

export type GetOrderChatMessageNotificationCreatedBy = {
	name: string
	type: GetOrderChatMessageCreatedByType
}

export enum GetOrderChatMessageCreatedByType {
	Client = "Client",
	Consumer = "Consumer",
}

type GetNotificationBase = {
	id: string
	clientIdentifier: string
	sendStatus: NotificationSendStatus
	createdAt: number
	readBy: GetNotificationReadBy
	readStatus: NotificationReadStatus
}

export type GetOrderChatMessageNotification = GetNotificationBase & {
	type: "OrderChatMessage"
	orderId: string
	truncatedMessage: string
	consumerName: string
	createdBy: GetOrderChatMessageNotificationCreatedBy
	orderMarking: string
}

export type GetNewConsumerNotification = GetNotificationBase & {
	type: "NewConsumer"
	consumerName: string
	consumerRef: string
}

export type GetPendingConsumerNotification = GetNotificationBase & {
	type: "PendingConsumer"
	consumerName: string
	consumerRef: string
}

export type GetNotification = GetOrderChatMessageNotification | GetNewConsumerNotification | GetPendingConsumerNotification

export type GetNotifications = {
	notifications: GetNotification[]
}

export type GetNotificationsLimited = {
	notifications: GetNotification[]
	totalUnread?: number
}

type MarkMultipleNotificationRead = {
	notificationIds: string[]
}

type Props = {}

type CheckedRows = { [notificationId: string]: boolean }

export const NotificationsList: FC<Props> = (props: Props) => {
	const client = useClient()
	const auth = useAuth()
	const navigator = useNavigator()
	const [queryParams, setQueryParams] = useSearchParams({ page: "1", pageSize: "20" })

	const [selectedConsumerId] = useBrandedLocalStorage("selected-consumer-id", z.string(), {
		defaultValue: "",
	})

	const [notifications, setNotifications] = useState<GetNotification[] | null>(null)
	const [newNotificationsAvailable, setNewNotificationsAvailable] = useState<boolean>(false)

	const [updatingReadStatus, setUpdatingReadStatus] = useState(false)

	const [checkedRows, setCheckedRows] = useState<CheckedRows>({})

	const fetchAllNotifications = useCallback(() => {
		if (auth.IsLoggedInClient) {
			API.getWithRetries<GetNotifications>(`/order-ui/notifications-v1/get-notifications-client`, true).then(
				(res) => {
					setNotifications(res.notifications)
				},
				() => {},
			)
		}

		if (auth.IsLoggedInConsumer && selectedConsumerId) {
			API.getWithRetries<GetNotifications>(
				`/order-ui/notifications-v1/get-notifications-consumer/${selectedConsumerId}`,
				true,
			).then(
				(res) => {
					setNotifications(res.notifications)
				},
				() => {},
			)
		}
	}, [auth.IsLoggedInClient, auth.IsLoggedInConsumer, selectedConsumerId])

	useEffect(() => {
		if (!client.features.notificationsEnabled) {
			navigator.open("order")
		}
	}, [])

	useEffect(() => {
		fetchAllNotifications()
	}, [fetchAllNotifications])

	useEffect(() => {
		const func = () => {
			setNewNotificationsAvailable(true)
		}
		document.addEventListener("new-notification-available", func)

		return () => {
			document.removeEventListener("new-notification-available", func)
		}
	}, [])

	function getPageNumber() {
		return parseNumberSafe(queryParams.get("page"), 1)
	}

	function getPageSize() {
		return parseNumberSafe(queryParams.get("pageSize"), 20)
	}

	function getClientsidePaging(notifications: GetNotification[], pageSize: number, pageNumber: number): Paging {
		return {
			pageNumber: pageNumber,
			pageSize: pageSize,
			totalPages: Math.ceil(notifications.length / pageSize),
			elementsInPage: pageSize,
			totalElements: notifications.length || 0,
			isFirst: pageNumber === 0,
			isLast: false,
			isEmpty: false,
			current: {
				page: 1,
				size: pageSize,
			},
		}
	}

	function paginatedNotifications(notifications: GetNotification[]): GetNotification[] {
		let ret: GetNotification[] = []
		const pageSize = getPageSize()
		const pageNumber = getPageNumber() - 1
		for (let i = pageSize * pageNumber; i < pageSize * (pageNumber + 1); i++) {
			const notification = notifications[i]
			if (notification) {
				ret.push(notification)
			}
		}
		return ret
	}

	function tableColumns(notifications: GetNotification[]): TableColumn<GetNotification>[] {
		return [
			{
				headerContent: (
					<input
						disabled={updatingReadStatus}
						type="checkbox"
						className={style.selectAllRowsCheckbox}
						checked={Object.keys(checkedRows).length === notifications.length}
						onChange={() => {}}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							setCheckedRows(() => {
								if (Object.keys(checkedRows).length === notifications.length) {
									return {}
								}

								const ret: CheckedRows = {}
								notifications.forEach((notice) => {
									ret[notice.id] = true
								})

								return ret
							})
						}}
					/>
				),
				cellContent: {
					content: (data) => {
						return (
							<input
								disabled={updatingReadStatus}
								type="checkbox"
								checked={checkedRows[data.id]}
								name="notificationReadCheckbox"
								onChange={() => {}}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									setCheckedRows((rows) => {
										if (rows[data.id]) {
											delete rows[data.id]
										} else {
											rows[data.id] = true
										}

										return { ...rows }
									})
								}}
							/>
						)
					},
					className: () => style.checkBoxCell,
				},
			},
			{
				headerContent: "Notifikation",
				cellContent: {
					content: (notification) => {
						return exhaustive(notification, "type", {
							OrderChatMessage: (it) => {
								return (
									<>
										<div className={style.notificationHeader}>
											{it.createdBy.type === GetOrderChatMessageCreatedByType.Client ? (
												<>
													{client.clientInfo.clientName}, {it.createdBy.name}
												</>
											) : (
												<>
													{it.consumerName}, {it.createdBy.name}
												</>
											)}
										</div>
										<div className={style.notificationMessageContentText}>
											har lämnat ett meddelande: "{it.truncatedMessage}"
										</div>
										<div className={style.notificationMarking}>{it.orderMarking}</div>
									</>
								)
							},
							NewConsumer: (it) => {
								return (
									<>
										<div className={style.notificationHeader}>
											<strong>{it.consumerName}</strong>
										</div>
										<div className={style.notificationMessageContentText}>
											är nu registrerad som kund
										</div>
									</>
								)
							},
							PendingConsumer: (it) => {
								return (
									<>
										<div className={style.notificationHeader}>
											<strong>{it.consumerName}</strong>
										</div>
										<div className={style.notificationMessageContentText}>
											har skapat en ansökan om att bli kund
										</div>
									</>
								)
							},
							_: () => null,
						})
					},
				},
			},
			{
				headerContent: "Händelsetid",
				cellContent: {
					content: (data) => (
						<div className={style.createdAt}>
							{DateTime.fromSeconds(data.createdAt).setLocale("sv-SE").toLocaleString(DateTime.DATETIME_MED)}
						</div>
					),
				},
			},
			{
				headerContent: "",
				cellContent: {
					content: (data) => {
						if (data.readStatus === NotificationReadStatus.Unread) {
							return <div className={style.notificationUnreadMarker}></div>
						}

						return <></>
					},
				},
			},
		]
	}

	function markCheckedRowsAsRead(ids: string[]) {
		setUpdatingReadStatus(true)

		const req: MarkMultipleNotificationRead = {
			notificationIds: ids,
		}

		API.postWithRetries<GetNotifications, MarkMultipleNotificationRead>(
			"/order-ui/notifications-v1/mark-multiple-as-read",
			req,
			undefined,
			10,
		).then(
			(response) => {
				setNotifications((notifications) => {
					if (!notifications) {
						return response.notifications
					}

					response.notifications.forEach((updatedRow) => {
						const index = notifications.findIndex((notice) => notice.id === updatedRow.id)
						if (index > -1) {
							notifications[index] = updatedRow
						}
					})

					return notifications
				})
				setCheckedRows({})
				setUpdatingReadStatus(false)
			},
			() => {},
		)
	}

	function selectedTableRowsSection() {
		const checkedRowIds = Object.keys(checkedRows)

		return (
			<div className={style.checkedRowsSection}>
				<div className={style.checkedRowAmountText}>
					Antal valda rader: <strong>{checkedRowIds.length}</strong>
				</div>
				<AccentButton
					disabled={checkedRowIds.length === 0 || updatingReadStatus}
					onClick={() => {
						markCheckedRowsAsRead(checkedRowIds)
					}}>
					Markera som lästa{" "}
					{updatingReadStatus ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
				</AccentButton>
			</div>
		)
	}

	function tableSection() {
		if (notifications === null) {
			return (
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			)
		}

		return (
			<div className={style.tableWrapper}>
				{rowsPerPageSection(
					{},
					getPageSize(),
					(page) => {
						setPageAndFilterInUrl(1, page, null, setQueryParams)
					},
					updatingReadStatus,
				)}
				<SkrappyTable
					data={paginatedNotifications(notifications)}
					columns={tableColumns(notifications)}
					tableClass={style.notificationsTable}
					tableRowClass={style.clickableRow}
					onRowClick={(data) => {
						exhaustive(data, "type", {
							OrderChatMessage: (it) => {
								navigator.open(`/${client.identifier}/my-pages/orders?order=${it.orderId}&tabId=Activity`)
							},
							NewConsumer: (it) => {
								navigator.open(
									`/${client.identifier}/my-pages/consumers?consumerId=${it.consumerRef}&tab=overview`,
								)
							},
							PendingConsumer: (it) => {
								navigator.open(`/${client.identifier}/my-pages/consumers?consumerId=${it.consumerRef}`)
							},
						})
					}}
				/>
				{tablePagingSection(
					getClientsidePaging(notifications, getPageSize(), getPageNumber()),
					{ pageNumber: getPageNumber(), pageSize: getPageSize() },
					() => {
						setPageAndFilterInUrl(getPageNumber() - 1, null, null, setQueryParams)
					},
					() => {
						setPageAndFilterInUrl(getPageNumber() + 1, null, null, setQueryParams)
					},
					(number) => {
						setPageAndFilterInUrl(number, null, null, setQueryParams)
					},
					updatingReadStatus,
				)}
			</div>
		)
	}

	return (
		<div>
			<div className={style.headerRow}>
				<div className={style.header}>Alla notifikationer (senaste 30 dagar)</div>
				{newNotificationsAvailable ? (
					<div>
						<div>
							<strong>Nya notifikationer tillgängliga</strong>
						</div>
						<AccentButton
							className={style.updateListButton}
							onClick={() => {
								fetchAllNotifications()
								setNewNotificationsAvailable(false)
							}}>
							Uppdatera lista
						</AccentButton>
					</div>
				) : null}
			</div>
			{selectedTableRowsSection()}
			{tableSection()}
		</div>
	)
}
