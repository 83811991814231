import { z } from "zod"
import { Articles } from "../Client/articleTrees/Articles"
import { DiscountDescriptionId } from "../CustomerPortal/CustomerPortalOrders/CustomerPortalOrders"
import { invalidPhoneNumberMessage, validatePhoneNumberForSweden } from "./Components/Form/constants"

export const ProjectContactPersonSchema = z.object({
	id: z.string().min(1),
	name: z.string().min(1),
	phone: z
		.string()
		.min(3, invalidPhoneNumberMessage)
		.refine(validatePhoneNumberForSweden, { message: invalidPhoneNumberMessage }),
})

export const ProjectSchema = z.object({
	street: z.string().min(1),
	city: z.string().min(1),
	zipcode: z.string().optional(),
	coordinates: z.custom<google.maps.LatLngLiteral>().optional(),
	deliveryCoordinates: z.custom<google.maps.LatLngLiteral>().optional(),
	marking: z.string().optional(),
	/**
	 * Set contactName, contactPhone and contactPersons to optional to
	 * keep backwards compatibility and not remove every project for every person on page load
	 */
	contactName: z.string().optional(),
	contactPhone: z.string().optional(),
	contactPersons: z.array(ProjectContactPersonSchema).optional(),
	migrated: z.boolean().optional(),
})

export type Project = z.input<typeof ProjectSchema>

export const WasteTypeSchema = z.object({
	wasteTypeId: z.string(),
	amount: z.number(),
})

export const SelectedProductPackagingMethodSchema = z.object({
	id: z.string(),
	amount: z.number(),
})

export const SelectedProductSchema = z.object({
	productId: z.string(),
	name: z.string(),
	amount: z.number().optional(),
	wasteType: WasteTypeSchema.optional(),
	serviceId: z.string().optional(),
	packagingMethod: SelectedProductPackagingMethodSchema.optional(),
	category: z.string(),
	uniqueId: z.string(), // used to give each object a unique id
})

const OrderItemTimeNamedSlotSchema = z.object({
	timeslotId: z.string(),
	specificTime: z.literal(false),
	timeName: z.string(),
})

const OrderItemSpecificTimeSchema = z.object({
	timeslotId: z.string(),
	specificTime: z.literal(true),
	timeValue: z.string(),
})

export const OrderItemTimeSchema = z.discriminatedUnion("specificTime", [
	OrderItemTimeNamedSlotSchema,
	OrderItemSpecificTimeSchema,
])

export const OrderItemDateSchema = z.object({
	dateSlotId: z.string(),
	specificDate: z.string().optional(),
})

export const OrderItemsProductsSchema = z.array(SelectedProductSchema)

export const OrderItemSchema = z.object({
	id: z.string(),
	project: ProjectSchema,
	date: z.string().optional().or(OrderItemDateSchema.optional()),
	time: OrderItemTimeSchema.optional(),
	products: OrderItemsProductsSchema,
	category: z.string(),
	serviceId: z.string().optional(),
	transportId: z.string(), //Not needed in order request
	zoneId: z.string().optional(),
})

export type OrderItemDateType = OrderItemType["date"]

export const OrderItemsSchema = z.array(OrderItemSchema)
export type OrderItems = z.input<typeof OrderItemsSchema>

export type OrderItemType = z.input<typeof OrderItemSchema>

export type OrderItem = OrderItemType & {
	articles?: Articles
	products: OrderItemsProductsType
}

export type OrderItemProductType = z.input<typeof SelectedProductSchema>
export type OrderItemProduct = OrderItemProductType & {
	articles?: Articles
	discountDescriptionRef?: DiscountDescriptionId
}
export type OrderItemsProductsType = OrderItemProduct[]

export type OrderItemTimeType = z.input<typeof OrderItemTimeSchema>

export enum LicenseOptions {
	AGREE = "AGREE",
	DECLINED = "DECLINED",
}

export type LicenseAgreement = {
	skrappy: LicenseOptions
	client: LicenseOptions | null
}
