import { isEqual } from "lodash"
import { getLogger } from "loglevel"
import React, { ReactNode, useContext, useEffect, useState } from "react"
import { AbsolutCentered } from "./AbsolutCentered/AbsolutCentered"
import { useAuth } from "./Auth/AuthContext"
import { useConsumer } from "./Client/ConsumerContext"
import { Loader } from "./Loader/Loader"
import { API } from "./network/API"

const logger = getLogger("PermissionContext")

export enum PermissionAreaState {
	ALLOW = "ALLOW",
	DENY = "DENY",
}

export enum PermissionAreaLocation {
	Orders_Read = "Orders_Read",
	Order_Process_Start = "Order_Process_Start",
	Order_Activities_Read = "Order_Activities_Read",
	Order_Activities_Create = "Order_Activities_Create",
	Order_Attachments_Read = "Order_Attachments_Read",
	Order_Attachments_Create = "Order_Attachments_Create",
	Order_Attachments_Delete = "Order_Attachments_Delete",
	Notifications_Read = "Notifications_Read",

	Consumer_Information_View = "Consumer_Information_View",
	Consumer_Information_Profile_Picture_Edit = "Consumer_Information_Profile_Picture_Edit",
	Consumer_Attachments_View = "Consumer_Attachments_View",
	Consumer_Attachments_Create = "Consumer_Attachments_Create",
	Consumer_Attachments_Delete = "Consumer_Attachments_Delete",
	Consumer_Users_Read = "Consumer_Users_Read",
	Consumer_Users_Delete = "Consumer_Users_Delete",
	Consumer_User_Invitations_Create = "Consumer_User_Invitations_Create",
	Consumer_User_Invitations_Read = "Consumer_User_Invitations_Read",
	Consumer_User_Invitations_Resend = "Consumer_User_Invitations_Resend",
	Consumer_User_Invitations_Recall = "Consumer_User_Invitations_Recall",
	Consumer_Contact_Persons_Read = "Consumer_Contact_Persons_Read",
	Consumer_Contact_Persons_Create = "Consumer_Contact_Persons_Create",
	Consumer_Contact_Persons_Update = "Consumer_Contact_Persons_Update",
	Consumer_Contact_Persons_Delete = "Consumer_Contact_Persons_Delete",
	Consumer_Addresses_Read = "Consumer_Addresses_Read",
	Consumer_Addresses_Create = "Consumer_Addresses_Create",
	Consumer_Addresses_Update = "Consumer_Addresses_Update",
	Consumer_Addresses_Delete = "Consumer_Addresses_Delete",
	Consumer_Invoicing_Information_Read = "Consumer_Invoicing_Information_Read",
	Consumer_Invoicing_Information_Update = "Consumer_Invoicing_Information_Update",

	Client_Information_View = "Client_Information_View",
	Client_Information_Update = "Client_Information_Update",
	Client_Payment_Details_Read = "Client_Payment_Details_Read",
	Client_Payment_Details_Update = "Client_Payment_Details_Update",
	Client_Users_Read = "Client_Users_Read",
	Client_Users_Delete = "Client_Users_Delete",
	Client_Orders_Update = "Client_Orders_Update",
	Client_Consumers_Read = "Client_Consumers_Read",
	Client_Consumers_Create = "Client_Consumers_Create",
	Client_Consumer_Information_Update = "Client_Consumer_Information_Update",
	Client_Consumer_Inactivate = "Client_Consumer_Inactivate",
	Client_Consumer_Documents_View = "Client_Consumer_Documents_View",
	Client_Consumer_Address_Marking_Update = "Client_Consumer_Address_Marking_Update",
	Client_Order_External_Reference_Edit = "Client_Order_External_Reference_Edit",
	Client_Pending_Consumer_Approve_Deny = "Client_Pending_Consumer_Approve_Deny",
	Client_Pending_Consumer_Approve_Already_Denied = "Client_Pending_Consumer_Approve_Already_Denied",
}

export class PermissionService {
	public readonly resolvedPermissions: PermissionsType
	public readonly isLoggedIn: boolean

	private readonly loggedOutPermissions: PermissionsType = {
		Order_Process_Start: PermissionAreaState.ALLOW,
		Consumer_Addresses_Read: PermissionAreaState.ALLOW,
		Consumer_Addresses_Create: PermissionAreaState.ALLOW,
	}

	constructor(resolvedPermissions: PermissionsType, isLoggedIn: boolean) {
		this.resolvedPermissions = resolvedPermissions
		this.isLoggedIn = isLoggedIn
	}

	public isAllowed(location: PermissionAreaLocation) {
		// Access control is based on logged in users.
		// If you're not logged in then there's no access control and all is allowed
		if (!this.isLoggedIn) {
			return this.loggedOutPermissions[location] === PermissionAreaState.ALLOW
		}

		return this.resolvedPermissions[location] === PermissionAreaState.ALLOW
	}
}

const PermissionContext = React.createContext<PermissionService | null>(null)

type Props = {
	children: ReactNode
}

type PermissionsType = Partial<Record<PermissionAreaLocation, PermissionAreaState>>

export function PermissionContextProvider({ children }: Props) {
	const consumer = useConsumer()
	const auth = useAuth()

	const [service, setService] = useState<PermissionService | "loading">("loading")

	useEffect(() => {
		if (!auth.IsLoggedIn) {
			setContextValue({}, false)
			return
		}

		if (auth.IsLoggedInConsumer && !consumer) {
			return
		}

		getResolvedPermissionRole(consumer?.consumerId)
	}, [auth.IsLoggedIn, auth.IsLoggedInConsumer, consumer])

	function setContextValue(permissions: PermissionsType, isLoggedIn: boolean) {
		if (
			service instanceof PermissionService &&
			isEqual(service.resolvedPermissions, permissions) &&
			service.isLoggedIn === isLoggedIn
		) {
			// No need to update the context value if the given values are the exact same as what we have
			return
		}

		setService(new PermissionService(permissions, isLoggedIn))
	}

	function getResolvedPermissionRole(maybeConsumerId?: string) {
		if (!auth.IsLoggedIn) {
			return
		}

		API.get<PermissionsType>(`/permissions-v1/resolved-permissions/${maybeConsumerId || ""}`).then(
			(res) => {
				setContextValue(res, true)
			},
			(err) => {
				logger.error(
					`Failed to get resolved permissions, consumerId: ${
						maybeConsumerId || "none set"
					}, defaulting to all DENY`,
					err,
				)
				setContextValue({}, true)
			},
		)
	}

	if (service === "loading") {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	return <PermissionContext.Provider value={service}>{children}</PermissionContext.Provider>
}

const usePermissionsContext = () => useContext(PermissionContext)

export function usePermissions(): PermissionService {
	const context = usePermissionsContext()
	if (!context) {
		throw new Error("Permissions context does not exist")
	}

	return context
}
