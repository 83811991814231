import { faAngleRight, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import sv from "date-fns/locale/sv"
import { exhaustive } from "exhaustive"
import { isArray, uniqBy } from "lodash"
import { FC, useEffect, useState } from "react"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { v4 } from "uuid"
import { z } from "zod"
import {
	ConsumerState,
	GetConsumer,
	GetConsumerType,
} from "../../CustomerPortal/CustomerPortalConsumerInformation/CustomerPortalConsumerInformation"
import { ArrowLeftIcon, CrossIcon } from "../../Icons/Icon"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { consumerStateToHumanText } from "../ClientPortalConsumerManager/ClientPortalConsumerManager"
import style from "./ConsumerListFilterSidebar.module.css"
registerLocale("sv", sv)
setDefaultLocale("sv")

type Props = {
	onClose: () => void
	onDone: (filterData: ConsumerListFilterData) => void
	consumers: GetConsumer[]
	filterData?: ConsumerListFilterData
}

export type ConsumerListFilterOptionType =
	| "name"
	| "customerNumber"
	| "consumerType"
	| "state"
	| "hasOrders"
	| "hasUsers"
	| "hasInvites"
	| "isNew"

type ConsumerListFilterOption = {
	name: string
	type: ConsumerListFilterOptionType
}

export const consumerListFilterOptions: ConsumerListFilterOption[] = [
	{
		name: "Kund",
		type: "name",
	},
	{
		name: "Kundnummer",
		type: "customerNumber",
	},
	{
		name: "Kundtyp",
		type: "consumerType",
	},
	{
		name: "Status",
		type: "state",
	},
	{
		name: "Ny kund?",
		type: "isNew",
	},
	{
		name: "Har ordrar?",
		type: "hasOrders",
	},
	{
		name: "Har användare?",
		type: "hasUsers",
	},
	{
		name: "Har inbjudningar?",
		type: "hasInvites",
	},
]

type ConsumerListSelectionItem = {
	$id: string
	key: string
	value: string
}

export enum OrderByType {
	Orders = "Orders",
	Users = "Users",
	Invites = "Invites",
}

export enum SortByDirection {
	ASC = "ASC",
	DESC = "DESC",
}

export const ConsumerListFilterSortBy = z.object({
	type: z.nativeEnum(OrderByType),
	direction: z.nativeEnum(SortByDirection),
})

export const ConsumerListFilterDataSchema = z.object({
	name: z.string().array().optional(),
	customerNumber: z.string().array().optional(),
	consumerType: z.nativeEnum(GetConsumerType).optional(),
	state: z.string().array().optional(),
	searchText: z.string().optional(),
	hasOrders: z.string().optional(),
	hasUsers: z.string().optional(),
	hasInvites: z.string().optional(),
	isNew: z.string().optional(),
	sortBy: ConsumerListFilterSortBy.optional(),
})

export type ConsumerListFilterDataSchemaType = z.input<typeof ConsumerListFilterDataSchema>

export type ConsumerListFilterData = {
	name?: string[]
	customerNumber?: string[]
	consumerType?: GetConsumerType
	state?: string[]
	searchText?: string
	hasOrders?: string
	hasUsers?: string
	hasInvites?: string
	isNew?: string
}
export const ConsumerListFilterSidebar: FC<Props> = ({ onClose, consumers, filterData, onDone }) => {
	const [openedCategory, setOpenedCategory] = useState<ConsumerListFilterOptionType | null>(null)

	const [selectedFilterData, setSelectedFilterData] = useState<ConsumerListFilterData>(filterData || {})

	const [nameOptions, setNameOptions] = useState<ConsumerListSelectionItem[]>([])
	const [customerNumberOptions, setCustomerNumberOptions] = useState<ConsumerListSelectionItem[]>([])
	const consumerTypeOptions: ConsumerListSelectionItem[] = [
		{
			$id: v4(),
			key: GetConsumerType.Company,
			value: "Företag",
		},
		{
			$id: v4(),
			key: GetConsumerType.PrivatePerson,
			value: "Privatpersoner",
		},
	]
	const stateOptions: ConsumerListSelectionItem[] = [
		{
			$id: v4(),
			key: ConsumerState.Active,
			value: consumerStateToHumanText(ConsumerState.Active),
		},
		{
			$id: v4(),
			key: ConsumerState.Inactive,
			value: consumerStateToHumanText(ConsumerState.Inactive),
		},
		{
			$id: v4(),
			key: ConsumerState.Pending,
			value: consumerStateToHumanText(ConsumerState.Pending),
		},
		{
			$id: v4(),
			key: ConsumerState.Denied,
			value: consumerStateToHumanText(ConsumerState.Denied),
		},
	]
	const yesNoOptions: ConsumerListSelectionItem[] = [
		{
			$id: v4(),
			key: "true",
			value: "Ja",
		},
		{
			$id: v4(),
			key: "false",
			value: "Nej",
		},
	]

	const [textSearchValue, setTextSearchValue] = useState<string>("")

	useEffect(() => {
		const names: ConsumerListSelectionItem[] = []
		const customerNumbers: ConsumerListSelectionItem[] = []
		consumers.forEach((consumer) => {
			if (consumer.consumerDetails.name) {
				names.push({ key: consumer.consumerDetails.name, value: consumer.consumerDetails.name, $id: v4() })
			}

			if (consumer.customerNumber) {
				customerNumbers.push({ key: consumer.customerNumber, value: consumer.customerNumber, $id: v4() })
			}
		})
		setNameOptions(sortStringOptionsAscending(uniqBy(names, (opt) => opt.key.toLocaleLowerCase())))
		setCustomerNumberOptions(sortStringOptionsAscending(uniqBy(customerNumbers, (opt) => opt.key.toLocaleLowerCase())))
	}, [consumers])

	function sortStringOptionsAscending(options: ConsumerListSelectionItem[]): ConsumerListSelectionItem[] {
		return options.sort((a, b) => {
			if (a.key.toLocaleLowerCase() > b.key.toLocaleLowerCase()) {
				return 1
			}
			if (b.key.toLocaleLowerCase() > a.key.toLocaleLowerCase()) {
				return -1
			}
			return 0
		})
	}

	function getData(type: ConsumerListFilterOptionType): ConsumerListSelectionItem[] {
		let ret: ConsumerListSelectionItem[] = exhaustive(type, {
			name: () => nameOptions,
			customerNumber: () => customerNumberOptions,
			consumerType: () => consumerTypeOptions,
			state: () => stateOptions,
			isNew: () => yesNoOptions,
			hasInvites: () => yesNoOptions,
			hasOrders: () => yesNoOptions,
			hasUsers: () => yesNoOptions,
		})

		if (textSearchValue) {
			ret = ret.filter((x) => x.value.toLocaleLowerCase().indexOf(textSearchValue.toLocaleLowerCase()) > -1)
		}

		return ret
	}

	function setSelectedValue(value: string, type: keyof ConsumerListFilterData) {
		const typeStoredAsArray = exhaustive(type, {
			searchText: () => false,
			consumerType: () => false,
			hasUsers: () => false,
			hasOrders: () => false,
			hasInvites: () => false,
			name: () => true,
			customerNumber: () => true,
			state: () => true,
			isNew: () => false,
		})

		if (!typeStoredAsArray) {
			selectedFilterData[type] = value as any
		} else {
			if (isArray(selectedFilterData[type])) {
				const arr = selectedFilterData[type] as string[]
				if (arr.includes(value)) {
					arr.splice(arr.indexOf(value), 1)
					if (arr.length === 0) {
						delete selectedFilterData[type]
					}
				} else {
					;(selectedFilterData[type] as string[]).push(value)
				}
			} else {
				;(selectedFilterData[type] as string[]) = [value]
			}
		}
		setSelectedFilterData(Object.assign({}, selectedFilterData))
	}

	function categoriesItems(): JSX.Element | null {
		if (openedCategory) {
			return null
		}

		return (
			<div style={{ position: "relative", height: "calc(100% - 60px)" }}>
				<div className={style.clearAllFiltersButtonWrapper}>
					<button
						className={style.clearAllFiltersButton}
						disabled={Object.keys(selectedFilterData).filter((x) => x).length === 0}
						onClick={() => {
							setSelectedFilterData({})
						}}>
						Rensa alla filter
					</button>
				</div>
				<span style={{ height: "calc(100% - 65px)", display: "block", overflowY: "auto" }}>
					{consumerListFilterOptions.map((x) => {
						return (
							<div
								key={v4()}
								className={style.optionsHeader}
								onClick={() => {
									setOpenedCategory(x.type)
								}}>
								<FontAwesomeIcon icon={faAngleRight} style={{ width: "15px" }} /> {x.name}{" "}
								{selectedFilterData[x.type] !== undefined ? (
									<button
										className={style.unselectOptionButton}
										onClick={(event) => {
											event.preventDefault()
											event.stopPropagation()
											delete selectedFilterData[x.type]
											setSelectedFilterData(Object.assign({}, selectedFilterData))
										}}>
										Ta bort
									</button>
								) : null}
							</div>
						)
					})}
				</span>

				<FinalizeButton
					onClick={() => {
						setOpenedCategory(null)
						onClose()
						onDone(selectedFilterData)
					}}
					className={style.finalizeButton}>
					Klar
				</FinalizeButton>
			</div>
		)
	}

	function openedCategoryItems(): JSX.Element | null {
		if (!openedCategory) {
			return null
		}

		const disableFinalizeButtons = !selectedFilterData[openedCategory]

		return (
			<div style={{ position: "relative", height: "calc(100% - 60px)" }}>
				<div className={style.headerText} style={{ marginBottom: "20px", marginLeft: 0 }}>
					{consumerListFilterOptions.find((x) => x.type === openedCategory)?.name}
				</div>
				{openedCategory !== "state" && openedCategory !== "consumerType" ? (
					<span className={style.searchInputWrapper}>
						<span>
							<FontAwesomeIcon icon={faMagnifyingGlass} />
						</span>
						<input
							type="search"
							placeholder={"Sök"}
							value={textSearchValue}
							onChange={(e) => {
								setTextSearchValue(e.target.value)
							}}
						/>
					</span>
				) : null}
				<div className={style.selectionItemsWrapper}>
					{getData(openedCategory).map((dataItem) => {
						const defaultChecked =
							openedCategory === "hasInvites" ||
							openedCategory === "hasOrders" ||
							openedCategory === "hasUsers"
								? selectedFilterData[openedCategory] === dataItem.key
								: selectedFilterData[openedCategory]?.includes(dataItem.key)
						return (
							<div key={v4()} style={{ display: "flex", alignItems: "center", gap: "5px" }}>
								<input
									id={dataItem.$id + "_input"}
									type={openedCategory === "consumerType" ? "radio" : "checkbox"}
									name={openedCategory + "_name"}
									value={dataItem.key}
									defaultChecked={defaultChecked}
									onChange={(event) => {
										setSelectedValue(event.target.value, openedCategory)
									}}
									className={style.input}
								/>{" "}
								<label
									style={{ width: "240px", display: "flex", alignItems: "center" }}
									htmlFor={dataItem.$id + "_input"}>
									{dataItem.value.substring(0, 1).toLocaleUpperCase() + dataItem.value.substring(1)}
								</label>
							</div>
						)
					})}
				</div>
				<FinalizeButton
					onClick={() => {
						delete selectedFilterData[openedCategory]
						setSelectedFilterData(Object.assign({}, selectedFilterData))
					}}
					disabled={disableFinalizeButtons}
					className={style.finalizeButtonClear}>
					Rensa
				</FinalizeButton>
				<FinalizeButton
					onClick={() => {
						setOpenedCategory(null)
					}}
					disabled={disableFinalizeButtons}
					className={style.finalizeButton}>
					Välj
				</FinalizeButton>
			</div>
		)
	}

	return (
		<>
			<div className={style.backdrop} onClick={() => onClose()}></div>
			<div className={style.sidebar}>
				<div className={style.closeButtonWrapper}>
					{openedCategory ? (
						<span
							style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
							onClick={() => {
								setTextSearchValue("")
								setOpenedCategory(null)
							}}>
							<ArrowLeftIcon iconClassName={style.backArrow} size={28} />
						</span>
					) : (
						<>
							<div className={style.headerText}>Filtrera</div>
							<span
								className={style.closeX}
								onClick={() => {
									onClose()
								}}>
								<CrossIcon size={22} className={style.closeXIcon} iconClassName={style.closeXIconInside} />
							</span>
						</>
					)}
				</div>
				{!openedCategory ? categoriesItems() : openedCategoryItems()}
			</div>
		</>
	)
}
