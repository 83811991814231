import { isUndefined } from "lodash"

export const skrappyLighterGreen = "#BEFCDA"
export const skrappyDarkerGreen = "#7BD2B4"

const threeCharsColorRegex: RegExp = /^#[A-Fa-f0-9]{3}$/
const sixCharsColorRegex: RegExp = /^#[A-Fa-f0-9]{6}$/

// Gracefully stolen from https://stackoverflow.com/questions/21646738/convert-hex-to-rgba
// and modified for some extra oomph (readability).
const lottieColorFromHex = (hex: string): string => {
	if (threeCharsColorRegex.test(hex)) {
		const r = parseInt(hex.slice(1, 2), 16)
		const g = parseInt(hex.slice(2, 3), 16)
		const b = parseInt(hex.slice(3, 4), 16)

		return `"k":[${r / 255},${g / 255},${b / 255},1]`
	}

	if (sixCharsColorRegex.test(hex)) {
		const r = parseInt(hex.slice(1, 3), 16)
		const g = parseInt(hex.slice(3, 5), 16)
		const b = parseInt(hex.slice(5, 7), 16)

		return `"k":[${r / 255},${g / 255},${b / 255},1]`
	}

	return lottieColorFromHex(skrappyLighterGreen)
}

// This method requires a uniform color within the lottie file and that that color is set
// to black.
export const fixLottieColor = (lottieJson: Object, hex?: string): Object => {
	const lottie = JSON.stringify(lottieJson)
	const selectedColor = isUndefined(hex) ? skrappyLighterGreen : hex
	const colorFromHex = lottieColorFromHex(selectedColor)
	const fixed = lottie.replaceAll('"k":[0,0,0,1]', colorFromHex)
	return JSON.parse(fixed)
}
