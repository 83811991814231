import { zodResolver } from "@hookform/resolvers/zod"
import Lottie from "lottie-react"
import { OrderCompleted } from "Lottie/AnimationComponents"
import { API } from "network/API"
import { Footer } from "OrderAcceptance/Footer/Footer"
import { SbtRHFError } from "Orders/Components/Text/SbtInvalid/SbtRHFError"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { cls } from "Shared/cls"
import { z } from "zod"
import { fixLottieColor } from "../Lottie/Helpers"
import thinking from "../Lottie/thinking.json"
import style from "../Onboarding/Onboarding.module.css"
import {
	invalidOrganizationNumberMessage,
	invalidPhoneNumberMessage,
	organizationNumberRegex,
	validatePhoneNumberForSweden,
} from "../Orders/Components/Form/constants"
import { OnboardingHeader } from "./OnboardingHeader/OnboardingHeader"
import { OnboardingSessionApiObject } from "./types/onboardingTypes"

const StartOnboardingForm = z.object({
	name: z.string().min(1),
	organizationName: z.string().min(1),
	organizationNumber: z
		.string()
		.min(1, "Ett organisationsnummer krävs")
		.regex(organizationNumberRegex, invalidOrganizationNumberMessage),
	email: z.string().min(1).email(),
	phoneNumber: z
		.string()
		.min(3, invalidPhoneNumberMessage)
		.refine(validatePhoneNumberForSweden, { message: invalidPhoneNumberMessage }),
	jobTitle: z.string().min(1),
})

type StartOnboardingFormType = z.input<typeof StartOnboardingForm>
export const RequestOnboarding = () => {
	const [requestHasBeenSent, setRequestHasBeenSent] = useState(false)
	const {
		register: registerStartOnboardingForm,
		handleSubmit: handleSubmitStartOnboardingForm,
		formState: { isSubmitting: isSubmittingStartOnboardingForm, errors: errorsStartOnboardingForm },
	} = useForm<StartOnboardingFormType>({
		resolver: async (data, context, options) => {
			return zodResolver(StartOnboardingForm)(data, context, options)
		},
	})

	const onOnboardingSessionStartSubmit: SubmitHandler<StartOnboardingFormType> = async (data) => {
		const postBody: ClientCreateOnboardingSession = {
			name: data.name,
			titleAtOrganization: data.jobTitle,
			organizationName: data.organizationName,
			organizationNumber: data.organizationNumber,
			email: data.email,
			phoneNumber: data.phoneNumber,
		}
		API.post<OnboardingSessionApiObject, ClientCreateOnboardingSession>(`/onboarding/session-v1`, postBody).then(() => {
			setRequestHasBeenSent(true)
		})
	}

	if (requestHasBeenSent) {
		return (
			<div>
				<OnboardingHeader />
				<div className={style.pageBackground}>
					<div className={style.lottieWrapperNoMargin}>
						<OrderCompleted className={style.lottieAnimation} />
					</div>
					<h1 className={style.completOnboardingTitle}>Tack för intresset!</h1>
					<div className={style.completOnboardingSubTitle}>
						Du ska ha fått ett e-postmeddelande med en länk till din onboarding session.
					</div>
					<div className={style.completOnboardingSubTitle}>Glöm inte kolla skräpkorgen!</div>
				</div>
				<Footer />
			</div>
		)
	} else {
		return (
			<div className={style.pageBackground}>
				<OnboardingHeader />
				<div className={style.requestOnboardingBody}>
					<div className={style.lottieWrapperNoMargin}>
						<Lottie
							animationData={fixLottieColor(thinking)}
							className={cls(style.lottieAnimation, style.requestOnboardingLottieSize)}
							autoPlay
							loop
						/>
					</div>
					<h1 className={style.completOnboardingTitle}>Intresserad av Skrappy?</h1>
					<div className={style.completOnboardingSubTitle}>
						Fyll i dessa detaljer så får du ett mail med mer detaljer.
					</div>
					<div className={style.requestOnboardingPageContent}>
						<div className={style.requestOnboardingPageFiller} />
						<div>
							<form
								className={style.requestOnboardingFormInputArea}
								id="startOnboardingForm"
								onSubmit={handleSubmitStartOnboardingForm(onOnboardingSessionStartSubmit)}>
								<div className={style.requestOnboardingFormInputField}>
									<h3 className={style.requestOnboardingFormInputTitle}>Fullständigt namn*</h3>
									<input className={style.inputTextBox} {...registerStartOnboardingForm("name")} />
									<SbtRHFError error={errorsStartOnboardingForm.name} />
								</div>
								<div className={style.requestOnboardingFormInputField}>
									<h3 className={style.requestOnboardingFormInputTitle}>Telefonnummer*</h3>
									<input className={style.inputTextBox} {...registerStartOnboardingForm("phoneNumber")} />
									<SbtRHFError error={errorsStartOnboardingForm.phoneNumber} />
								</div>
								<div className={style.requestOnboardingFormInputField}>
									<h3 className={style.requestOnboardingFormInputTitle}>E-post*</h3>
									<input className={style.inputTextBox} {...registerStartOnboardingForm("email")} />
									<SbtRHFError error={errorsStartOnboardingForm.email} />
								</div>
								<div className={style.requestOnboardingFormInputField}>
									<h3 className={style.requestOnboardingFormInputTitle}>Titel*</h3>
									<input className={style.inputTextBox} {...registerStartOnboardingForm("jobTitle")} />
									<SbtRHFError error={errorsStartOnboardingForm.jobTitle} />
								</div>
								<div className={style.requestOnboardingFormInputField}>
									<h3 className={style.requestOnboardingFormInputTitle}>Organisationsnamn*</h3>
									<input
										className={style.inputTextBox}
										{...registerStartOnboardingForm("organizationName")}
									/>
									<SbtRHFError error={errorsStartOnboardingForm.organizationName} />
								</div>
								<div className={style.requestOnboardingFormInputField}>
									<h3 className={style.requestOnboardingFormInputTitle}>Organisationsnummer*</h3>
									<input
										className={style.inputTextBox}
										{...registerStartOnboardingForm("organizationNumber")}
									/>
									<SbtRHFError error={errorsStartOnboardingForm.organizationNumber} />
								</div>
								<button disabled={isSubmittingStartOnboardingForm} className={style.sendOnboardingRequest}>
									Skicka in
								</button>
							</form>
						</div>

						<div className={style.requestOnboardingPageFiller} />
					</div>
				</div>
				<div></div>
				<Footer />
			</div>
		)
	}
}

type ClientCreateOnboardingSession = {
	name: string
	titleAtOrganization: string
	organizationName: string
	organizationNumber: string
	email: string
	phoneNumber: string
}
